import { Form, Input, Select, Upload, Avatar, Space, Badge, Row, Col, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { getAvatarColor } from '../../../utils/utils';
import { getPrioTagColor } from '../../../utils/utils';

const { TextArea } = Input;
const { Option } = Select;


function TicketCreateForm({form, assets, userList, loggedInUser}) {
    const [assetOptions, setAssetOptions] = useState([])
    const [users, setUsers] = useState(userList)
    const { t } = useTranslation();

    const initialStatus = 'OPEN';
    

    const onFinish = (values) => {
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }


    const buildAssetOptions = () => {
        let options = []

        assets.forEach((area) => {
            let tmpAreaAssets = []

            area.assets.forEach((asset) => {
                tmpAreaAssets.push({
                    label: asset.name,
                    value: asset.id,
                    position: asset.position
                })
            })

            tmpAreaAssets.sort((a, b) => a.position - b.position);

            if (tmpAreaAssets.length > 0) {
                let tmpAssetOptions = {
                    label: area.name,
                    options: tmpAreaAssets
                }
            options.push(tmpAssetOptions)
            }
        })

        options.sort((a, b) => {
            const positionA = a.options.length > 0 ? a.options[0].position : 0;
            const positionB = b.options.length > 0 ? b.options[0].position : 0;
            return positionA - positionB;
        });

        setAssetOptions(options)
    }

    useEffect(() => {
        buildAssetOptions()
    }, [assets]);

    useEffect(() => {
    }, users)

    return (
        <Form
            {...layout}
            autoComplete="off"
            name="basic"
            form={form}
            onFinish={onFinish}
            initialValues={{
                status: initialStatus,
                assigned_to: loggedInUser
            }}
        >
            <Form.Item
                label={t('tickets.forms.create.name')}
                name="title"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.name') + ' ' + t('tickets.forms.create.error-message')
                }]}  
            >
                <Input showCount maxLength={48}/>
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.description')}
                name="description"
                rules={[{ 
                    required: true,
                    message: t('tickets.forms.create.description') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            
            <Form.Item
                label={t('tickets.forms.create.asset')}
                name="asset"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.asset') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select
                    options={assetOptions}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.priority')}
                name="priority"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.priority') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select
                    style={{
                        
                    }}
                    options={[
                        {
                            value: 'MINOR',
                            label: <Tag color={getPrioTagColor('MINOR')}>MINOR</Tag>
                        },
                        {
                            value: 'MAJOR',
                            label: <Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag>
                        },
                        {
                            value: 'CRITICAL',
                            label: <Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag>
                        }
                    ]}
                />
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.assigned_to')}
                name="assigned_to"
                rules={[{
                    required: true,
                    message: t('tickets.forms.create.assigned_to') + ' ' + t('tickets.forms.create.error-message')
                }]}
            >
                <Select 
                    //options={assetOptions}
                    showSearch
                    filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                    optionFilterProp="children"
                    
                >
                    { 
                        (users || []).map((user) => {
                            return(
                                <Option key={user.id} value={user.id} searchterm={user.name}>
                                    <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                        {user.signature}
                                    </Avatar>  {user.name}
                                </Option>);
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={t('tickets.forms.create.status')}
                name="status"
                rules={[{ required: true}]}
            >
                <Select
                    style={{
                    
                    }}
                    //defaultValue="OPEN"
                    options={[
                        {
                            value: 'OPEN',
                            label: <Badge status="warning" text="Open" />
                        },
                        {
                            value: 'IN_PROGRESS',
                            label: <Badge status="#1677FF" text="In Progress" />
                        },
                        {
                            value: 'CLOSED',
                            label: <Badge status="success" text="Closed" />
                        }
                    ]}
                >
                </Select>
            </Form.Item>
            {/* <Form.Item
                label={t('tickets.forms.create.attachments')}
            >
                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger name="files" action="/upload.do">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item> */}
        </Form>
    )
}

export default TicketCreateForm;
