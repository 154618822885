import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Button, Col, Row, Table, Tabs, notification} from 'antd';
import React, { useState, useEffect, useContext, useRef } from 'react';
import AdminNavMenu from "../../components/Administration/AdminNavMenu";
import AssetTypesOverview from "../../components/Administration/AssetTypes/AssetTypesOverview";
import ModulesOverview from "../../components/Administration/Modules/ModulesOverview";
import IssuesOverview from "../../components/Administration/Issues/IssuesOverview";
import AssetTypesMenu from "../../components/Administration/AssetTypes/AssetTypesMenu";
import NotificationsOverview from "../../components/Administration/Notifications/NotificationsOverview";
import { useAuth } from 'react-oidc-context';
import { GlobalContext } from "../../utils/GlobalContext";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";
import PdfExportTestDocument from "../../utils/PdfExportTestDocument";

function Administration() {

    //Auth
    const auth = useAuth();

    const { t } = useTranslation();

    const [api, contextHolder] = notification.useNotification();
    const  notificationContext  = useContext(GlobalContext);
    const [hasServiceManagerRole, setRole] = useState(false)

    const [activeTab, setActiveTab] = useState("asset_types")

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
    }, [auth])

    

    useEffect(() => {
        if (window.location.hash !== "") {
            setActiveTab(window.location.hash.replace("#", ""))
        }

    }, [])

    function onChangeTab(activeKey) {
        setActiveTab(activeKey)
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeKey
    }


    return (
        <>
            {contextHolder}
            <div>
                <h2>{t('administration.general.title')}</h2>
                <Tabs
                    type='card'
                    size='large'
                    onChange={onChangeTab}
                    activeKey={activeTab}
                    items={
                        [
                            {
                                key: "asset_types",
                                label: t('administration.general.tabs.asset_types'),
                                children:
                                    <div style={{marginTop: "10px"}}>
                                        <AssetTypesOverview />
                                    </div>
                            },
                            {
                                key: "module",
                                label: t('administration.general.tabs.modules'),
                                children:
                                    <div style={{marginTop: "10px"}}>
                                        <ModulesOverview />
                                    </div>
                            },
                            {
                                key: "issues",
                                label: t('administration.general.tabs.issues'),
                                children:
                                    <div style={{marginTop: "10px"}}>
                                        <IssuesOverview />
                                    </div>
                            },
                            {
                                key: "notifications",
                                label: t('administration.general.tabs.notifications'),
                                children:
                                    <div style={{marginTop: "10px"}}>
                                        <NotificationsOverview auth={auth}/>
                                    </div>
                            },
                            /* {
                                key: "pdf-export-test",
                                label: "[PDF-Export-Test]",
                                children:
                                    <div style={{marginTop: "10px"}}>
                                        <PdfExportTestDocument/>
                                    </div>
                            }, */
                        ]

                    }
                />

            </div>
        </>
    );
}

export default Administration;
