import { useLoaderData } from "react-router-dom";
import CardTemplate from "../../components/Tickets/Details/CardTemplate";
import TicketSidebarArea from "../../components/Tickets/Details/TicketSidebarArea";
import MainAreaCard from "../../components/Tickets/Details/MainAreaCard";
import { useTranslation } from "react-i18next";
import { Layout, Col, Row, Tabs, Button, notification, Badge } from "antd";
import DocumentsArea from "../../utils/DocumentsArea";
import ChatArea from "../../components/Tickets/Details/ChatArea";
import TimeBookingArea from "../../components/Tickets/Details/TimeBookingArea";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from "../../utils/GlobalContext";
import { fetchApi } from "../../utils/RequestBuilder";

import TimelineArea from "../../components/Tickets/Details/TicketTimelineArea";
import { useAuth } from 'react-oidc-context';
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";
import AddRequestModal from "../../components/Requests/AddRequestModal";
import UpdateTicketModal from "../../components/Tickets/UpdateTicketModal";

const {Content, Sider} = Layout;

export async function loader({ params }) {
    return params.id;
}

function TicketDetails() {
    const ticketId = useLoaderData();
    const [asset, setAsset] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [hasServiceManagerRole, setRole] = useState(false)
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const  notificationContext  = useContext(GlobalContext);
    const [activeTab, setActiveTab] = useState("chat");
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)

    //Auth
    const auth = useAuth();
    const [user, setUser] = useState({sub: ""})

    //Fetch ticket from Server
    async function loadTicketData() {
        try {
            const jsonData = await fetchApi(auth, "/tickets/" + ticketId)
            setTicket(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    // Fetch asset
    async function loadAssetData() {
        try {
            const jsonData = await fetchApi(auth, "/assets/" + ticket.asset.id)
            setAsset(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    function onChangeTab(activeKey) {
        setActiveTab(activeKey)
        window.location = (""+window.location).replace(/#[A-Za-z0-9_\-]*$/,'')+"#"+activeKey
    }


    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            loadTicketData()
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
        // eslint-disable-next-line
    }, [auth, ticketId])

    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "services_updated":
                    //loadServicesData();   
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext])

    useEffect(() => {
        if (ticket.id !== undefined) {
            loadAssetData()
        }
    }, [ticket])

    useEffect(() => {
        if (window.location.hash !== "") {
            setActiveTab(window.location.hash.replace("#", ""))
        }
    }, [])

    return (
        <>
            {contextHolder}
            <Layout hasSider>
                <Sider
                    style={{
                        backgroundColor: '#f5f5f5',
                        overflow: 'auto',
                        height: '100vh',
                        position:'fixed',
                        right: '24px',
                        top: '88px',
                        bottom: 0,
                    }}
                    width = "680px"
                    theme= 'light'
                >
                    {ticket.id &&
                        <TicketSidebarArea
                            asset={asset}
                            ticket={ticket}
                            auth={auth}
                            callbackFct={loadTicketData}
                            hasManufacturerRole={hasServiceManagerRole}
                        />
                    }
                </Sider>
                <Layout
                    className="site-layout"
                    style={{
                        marginRight:596,
                    }}
                >
                    <Content
                        style={{
                            margin: '24px 16px 0px',
                            overflow: 'initial',
                        }}
                    >
                        <div>
                            <Row>
                                <Col span={6}>
                                    <div
                                        style={{
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "8px",
                                            padding: "0 10px 10px 10px",
                                            marginRight: "32px",
                                            paddingTop: "16px",
                                        }}
                                    >
                                            <TimelineArea
                                                ticket={ticket} 
                                                auth={auth}
                                            />
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div id="sub-content">
                                        <Row>
                                            <Col span={24}>
                                                <div 
                                                    style={{marginRight: "64px"}}
                                                >
                                                    {ticket.id &&
                                                        <MainAreaCard
                                                            ticket={ticket}
                                                            editCallback={() => setUpdateModalIsOpen(true)}
                                                        />
                                                    }
                                                </div> 
                                            </Col>
                                        </Row>
                                        <div
                                            style={{marginRight: "64px", marginTop: "16px"}}
                                        >
                                            <Tabs
                                                type='card'
                                                size='large'
                                                onChange={onChangeTab}
                                                activeKey={activeTab}   
                                                items={[
                                                    {
                                                        label: t('tickets.details.chat-area.header'),
                                                        key: 'chat',
                                                        children: 
                                                            <div>
                                                                <CardTemplate
                                                                    content={
                                                                        <ChatArea
                                                                            id={ticketId}
                                                                            auth={auth}
                                                                            hasManufacturerRole={hasServiceManagerRole}
                                                                        />
                                                                    }
                                                                />  
                                                            </div>
                                                    },
                                                    {
                                                        label: t('tickets.details.documents-area.header'),
                                                        key: 'documents',
                                                        children: 
                                                            <div>
                                                                <DocumentsArea
                                                                    objectId={ticketId}
                                                                    auth={auth}
                                                                    type={"ticket"}
                                                                />  
                                                            </div>
                                                    },
                                                    {
                                                        label: t('tickets.details.time-booking-area.header'),
                                                        key: 'time-booking',
                                                        children: 
                                                            <div>
                                                                <CardTemplate
                                                                    content={
                                                                        <TimeBookingArea
                                                                            ticketId={ticket.id}
                                                                            auth={auth}
                                                                            hasManufacturerRole={hasServiceManagerRole}
                                                                            updateCallback={loadTicketData}
                                                                        />
                                                                    }
                                                                />  
                                                            </div>
                                                    },  
                                                ]}
                                            />
                                        </div>                                   
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </Layout>
            { ticket.id !== undefined &&
                <UpdateTicketModal
                    callbackFct={loadTicketData}
                    isOpen={updateModalIsOpen}
                    auth={auth}
                    prefills={ticket}
                    closeModalCallback={() => setUpdateModalIsOpen(false)}
                    sfSyncCallback={loadTicketData}
                />
            }

        </>
    );
}

export default TicketDetails;


