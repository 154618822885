import { useTranslation } from 'react-i18next';
import { Form, Modal, notification, Button, Tooltip } from 'antd';
import { useEffect, useState } from "react";
import {changeTicketInfo, createNewRequest, fetchApi} from '../../utils/RequestBuilder';
import TicketUpdateForm from "./Forms/TicketUpdateForm";

function UpdateTicketModal({isOpen, auth, prefills, closeModalCallback, sfSyncCallback, callbackFct}) {
    const { t } = useTranslation();

    const [syncTrigger, setSyncTrigger] = useState(0) ;
    const [manufacturerEngineers, setManufacturerEngineers] = useState([]) ;
    const [form] = Form.useForm();

    //Show Ticket Updated Alert
    const [api, contextHolder] = notification.useNotification()

    const handleOk = () => {
        form.validateFields().then(() => {
            handleTicketUpdated(auth, form.getFieldsValue())
            closeModalCallback()
            form.resetFields()
        })
    }

    const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         })
    }

    async function handleTicketUpdated(auth, formData) {
        try {
            const result = await changeTicketInfo(auth, "/customers/tickets/", [
                {attributeName: "area", attributeValue: formData.area},
                {attributeName: "asset", attributeValue: formData.asset},
                {attributeName: "assigned_to_id", attributeValue: formData.manufacturerEngineerSelect},
                {attributeName: "case_detail", attributeValue: formData.caseDetailSelect[1]},
                {attributeName: "case_type", attributeValue: formData.caseType},
                {attributeName: "comment_internal", attributeValue: formData.comment},
                {attributeName: "contact", attributeValue: formData.contact},
                {attributeName: "customer", attributeValue: formData.customer},
                {attributeName: "description", attributeValue: formData.description},
                {attributeName: "location", attributeValue: formData.location[1]},
                {attributeName: "priority", attributeValue: formData.priority},
                {attributeName: "service_type_incoming", attributeValue: formData.serviceTypeIncoming},
                {attributeName: "service_type_processing", attributeValue: formData.serviceTypeProcessing},
                {attributeName: "status", attributeValue: formData.ticketStatus},
            ], prefills.id)

            //TODO
            showUpdateAlert(result);
            if (result === 200) {
                callbackFct();
            } 
        } catch (error) {
            console.error(error);
        }
    }

    function showUpdateAlert(response) {
        let desc = ""
        let title = ""
        let errorCode = ""
        if (response == 200) {
            title = t('tickets.details.sider-area.update-message.title.success')
            desc = t('tickets.details.sider-area.update-message.body.success')
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('tickets.details.sider-area.update-message.title.error')
            desc = t('tickets.details.sider-area.update-message.body.error')
            errorCode = '[' + t('tickets.details.sider-area.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    const handleCancel = () => {
        closeModalCallback()
    };

    // Function to handle sync
    const syncSFData = () => {
        sfSyncCallback()
        const trigger = syncTrigger + 1;
        setSyncTrigger(trigger);
    };

    async function loadManufacturerUsers() {
        try {
            const jsonData = await fetchApi(auth, "/manufacturer-engineers")
            setManufacturerEngineers(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    useEffect(() => {
        loadManufacturerUsers()
    }, [auth])


    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('tickets.forms.update.header')}
                open={isOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <>
                        <Tooltip title={t('requests.forms.create.footer.sync')}>
                            <Button
                                className="request-create-sync"
                                key="sync"
                                type="primary"
                                icon={<img src="/img/logos/salesforce_Logo.svg" alt="salesforce logo" style={{ width: 16, height: 16 }} />}
                                onClick={syncSFData}
                            />
                        </Tooltip>
                        <Button
                            key="cancel"
                            onClick={handleCancel}>
                            {t('requests.forms.create.footer.cancel')}
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}>
                            {t('requests.forms.create.footer.ok')}
                        </Button>
                    </>
                ]}
            >
                <TicketUpdateForm
                    form={form}
                    auth={auth}
                    manufacturerEngineers={manufacturerEngineers}
                    syncTrigger={syncTrigger}
                    prefills={prefills}
                />
            </Modal>
        </>
    );
}

export default UpdateTicketModal;
