import {Form, Select} from "antd";
import {useTranslation} from "react-i18next";

function EquipmentFilter({onChange, options, value, disabled}) {

    const { t } = useTranslation();

    return(
        <Form>
            <Select
                showSearch
                allowClear
                placeholder={t('requests.overview.filter.equipment')}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
                value={value}
                style={{minWidth: "150px"}}
                disabled={disabled}
            />
        </Form>
    )
}

export default EquipmentFilter