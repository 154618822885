import { Card, Row, Col, Button, Form, Tooltip, notification } from 'antd'
import {useTranslation} from "react-i18next"
import React, { useState, useEffect } from 'react'
import { EditOutlined } from "@ant-design/icons"
import Scrollbars from 'react-custom-scrollbars-2'
import {getCaseTypeIcon} from "../../../utils/utils"

function MainAreaCard({ticket, editCallback}) {

    const [ ticketData, setTicketData ] = useState(ticket)
    const [ form ] = Form.useForm()
    const { t, i18n } = useTranslation()

    //Show Ticket Information Update Alert
    const [api, contextHolder] = notification.useNotification()

    const titleSpan = 6
    const contentSpan = 18


    useEffect(() => {
        setTicketData(ticket)
    }, [ticket])

    useEffect(() => {
        form.resetFields()
    }, [ticketData])


    return (
        <>
            {contextHolder}
            <Scrollbars
                autohide="true"
                style={{minHeight: "28vh"}}
            >
                <Card
                    className="tickets-details-card"
                    style={{maxHeight: "28vh"}}
                    title={
                        <div>
                            <Row
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        alignSelf: 'center'
                                    }}
                                >
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <h2>
                                            <Tooltip title={t('tickets.details.case-type-options.' + ticket.case_type.toLowerCase())}>
                                                {getCaseTypeIcon(ticket.case_type)}
                                            </Tooltip>
                                            &nbsp;
                                            {t('tickets.details.area-options.' + ticket.area.toLowerCase())} - {ticket.case_detail}
                                            <Button
                                                onClick={editCallback}
                                                type="link"
                                            >
                                                <EditOutlined />
                                            </Button>
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                >
                    <div>
                        <Row key='1'>
                            <Col span={titleSpan}>
                                {t('tickets.details.description')}
                            </Col>
                            <Col span={contentSpan}>
                                {ticket.description}
                            </Col>
                        </Row>
                        <Row key='2'>
                            <Col span={titleSpan}>
                                {t('tickets.details.main-area.comment')}
                            </Col>
                            <Col span={contentSpan}>
                                {ticket.comment_internal}
                            </Col>
                        </Row>
                        {/*<Row key='3'>
                            <Col span={titleSpan}>
                                {t('tickets.details.main-area.solution')}
                            </Col>
                            <Col span ={contentSpan}>
                                -
                            </Col>
                        </Row>
                        */}
                    </div>
                </Card>
            </Scrollbars>
        </>
    )
}

export default MainAreaCard;
