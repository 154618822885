import {Link} from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Space, notification } from 'antd';
import NavMenu from "../../components/Assets/NavMenu";
import WorkstationDetails from "../../components/Assets/WorkstationDetails";
import AddWorkstationModal from "../../components/Assets/AddWorkstationModal";
import AssetDetails from "../../components/Assets/AssetDetails";
import { fetchApi } from "../../utils/RequestBuilder";
import {useState, useEffect, useContext} from "react";
import { PlusOutlined, SwapOutlined } from "@ant-design/icons";
import { useAuth } from 'react-oidc-context';
import Scrollbars from "react-custom-scrollbars-2";
import { GlobalContext } from "../../utils/GlobalContext";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";
import ChangeWorkstationPositionModal from "../../components/Assets/ChangeWorkstationPositionModal";

export function loader() {
    const testAssets = [
        {
            id: 1,
            name: "Hello"
        },
        {
            id: 2,
            name: "World"
        }
    ]
    return  {testAssets} ;
}

function Assets() {

    //Auth
    const auth = useAuth();

    const [assetList, setAssetList] = useState([])
    const { t } = useTranslation();

    const [asset, setAsset] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: "", type: "", manufacturer: "", equipment_number: ""});
    const [workstation, setWorkstation] = useState({selectId: "", name: "", internal_identifier: "", id: "", position: ""});
    const [workstationSelected, setWorkstationSelected] = useState(true);
    const [menuItemKey, setMenuItemKey] = useState(["001"]);

    const [addWsModalIsOpen, setWsModalIsOpen] = useState(0);
    const [changeWsPositionModalIsOpen, setChangeWsPositionModalIsOpen] = useState(0);

    const [api, contextHolder] = notification.useNotification();
    const  notificationContext  = useContext(GlobalContext);
    const [hasServiceManagerRole, setRole] = useState(false);
    const [forceRerender, setForceRerender] = useState(0);

    useEffect(() => {
       loadAssets();
    }, [forceRerender]);

    //Fetch assets from Server
    async function loadAssets() {
        try {
            const jsonData = await fetchApi(auth, "/assets")
            setAssetList(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            loadAssets();
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER))
        }
    }, [auth])



    const setSelection = (selectId, name, selectType, internal_identifier, id, position, type, manufacturer, equipment_number) => {
        if (selectType === "workstation") {
            setWorkstation({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position
            })
            setAsset({
                selectId: "",
                name: "",
                internal_identifier: "",
                id: "",
                position: "",
                type: "",
                manufacturer: "",
                equipment_number: ""
            })
            setWorkstationSelected(true)
        } else {
            setAsset({
                selectId: selectId,
                name: name,
                internal_identifier: internal_identifier,
                id: id,
                position: position,
                type: type,
                manufacturer: manufacturer,
                equipment_number: equipment_number
            })
            setWorkstationSelected(false)
        }
    }

    

    const openWsModal = () => {
        setWsModalIsOpen(addWsModalIsOpen + 1)
    };
    const openWsPositionModal = () => {
        setChangeWsPositionModalIsOpen(changeWsPositionModalIsOpen + 1)
    };

    return (
        <>
            {contextHolder}
            <div>
                <Row>
                    <Col span={4} >
                        <h1>{t('assets.overview.header')}</h1>
                    </Col>
                    <Col span={20} style={{display: "flex"}}>
                        
                            <Space align="center">
                                <Button
                                    onClick={() => openWsModal()}
                                    type="primary">
                                        <PlusOutlined />
                                        {t('assets.overview.add-workstation')}
                                </Button>
                                <Button
                                    onClick={() => openWsPositionModal()}
                                    type="primary">
                                        <SwapOutlined rotate={90} />
                                        {t('assets.overview.change-ws-position')}
                                </Button>
                            </Space>
                        
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <div>
                            <Scrollbars
                                autohide="true"
                                style={{minHeight: "80vh"}}
                            >
                                <NavMenu setSelection={setSelection} externalSetKey={asset.id} assetList={assetList} menuItemKey={menuItemKey} />
                            </Scrollbars>
                        </div>
                    </Col>
                    <Col span={20}>
                        {
                            workstationSelected ?
                                <WorkstationDetails
                                    selectId={workstation.selectId} 
                                    name={workstation.name} 
                                    setSelection={setSelection} 
                                    auth={auth}
                                    id={workstation.id}
                                    assets={assetList} 
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey}
                                    menuItemKey={menuItemKey}
                                    setForceRerender={setForceRerender}
                                />
                                : 
                                <AssetDetails 
                                    selectId={asset.selectId} 
                                    name={asset.name} 
                                    auth={auth}
                                    id={asset.id} 
                                    type={asset.type} 
                                    manufacturer={asset.manufacturer} 
                                    equipment_number={asset.equipment_number}
                                    callbackFct={loadAssets}
                                    setMenuItemKey={setMenuItemKey} 
                                    assets={assetList}
                                    setForceRerender={setForceRerender}
                                />
                        }
                    </Col>
                </Row>
                <AddWorkstationModal isOpen={addWsModalIsOpen} auth={auth} callbackFct={loadAssets} />
                <ChangeWorkstationPositionModal 
                    isOpen={changeWsPositionModalIsOpen} 
                    auth={auth} 
                    setSelection={setSelection} 
                    externalSetKey="" 
                    assetList={assetList} 
                    menuItemKey={menuItemKey} 
                    workstation_id=""
                    setForceRerender={setForceRerender}
                />
            </div>
        </>
        
    );
}

export default Assets;
