import {
    red,
    volcano,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    grey,
  } from '@ant-design/colors';
import {CalendarOutlined, ThunderboltOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import {useTranslation} from "react-i18next";


//Get matching date format for selected Language
export function getDateFormat(lng)  {
    switch(lng) {
      case 'en':
        return 'YYYY/MM/DD hh:mm:ss a';
      case 'de':
        return 'DD.MM.YYYY HH:mm:ss';
      default:
        return 'YYYY/MM/DD hh:mm:ss a';
    }
  }


//Get Tag color fpr Priority Tag
export function getPrioTagColor(priority) {
    switch(priority) {
        case 'MINOR':
            return 'green';
        case 'MAJOR': 
            return 'orange';
        case 'CRITICAL':
            return 'red';
        default:
            return 'blue';
    }
}

//Get Avatar color based on first character of signature
export function getAvatarColor(signature) {
    let x = (signature || []).slice(0,1);
    switch(x) {
        case 'A':
        case 'F': 
        case 'M':
            return '#08605F'; 
        case 'Q':
        case 'Ü':
        case 'T':
            return '#3E2F5B';
        case 'O':
        case 'S':
            return '#623CEA';
        case 'L':
        case 'B':
        case 'Ä':
            return '#022F40';
        case 'U':
        case 'C':
            return '#664C43';
        case 'R':
        case 'P': 
        case 'X':
            return '#792359';
        case 'V':
        case 'K':
            return '#A4036F';
        case 'H':
        case 'N':
        case 'G':
            return '#243010';
        case 'J':
        case 'W':
            return '#A755C2';
        case 'Z':
        case 'Ö':
            return '#333138';
        case 'I':
        case 'D':
        case 'Y':
            return '#FFE066';
        

        default: return '#9D9C9B'
    }
}
    export function transformToSelectablesUtils(originalData) {
        const selectables = {};
      
        originalData.forEach((workstation) => {
          const workstationKey = workstation.position.toString().padStart(3, '0');
          const workstationName = workstation.name;
          const workstationInternalId = workstation.internal_identifier;
          const workstationId = workstation.id;
          const workstationPosition = workstation.position;
      
          selectables[workstationKey] = {
            type: "workstation",
            name: workstationName,
            internal_identifier: workstationInternalId,
            id: workstationId,
            position: workstationPosition,
          };
      
          if (workstation.assets) {
            workstation.assets.forEach((asset) => {
                const assetKey = `${workstationKey}.${asset.position.toString().padStart(1, '0')}`;
                const assetName = asset.name;
                const assetInternalId = asset.internal_identifier;
                const assetId = asset.id;
                const assetPosition = asset.position;
                const assetType = asset.type;
                const assetManufacturer = asset.manufacturer;
                const assetNo = asset.equipment_number;
      
                selectables[assetKey] = {
                    name: assetName,
                    internal_identifier: assetInternalId,
                    id: assetId,
                    position: assetPosition,
                    type: assetType,
                    manufacturer: assetManufacturer,
                    equipment_number: assetNo
                };
            });
          }
        });

        return selectables;
}
    
export function getCaseTypeIcon(caseType) {
    switch (caseType) {
        case 'FAULT':
            return <ThunderboltOutlined />
        case 'SERVICE':
            return <CalendarOutlined />
    }
}
