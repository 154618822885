import { Form, Input, Select, Avatar, Badge, Tag, Row, Col, Cascader, Tooltip, Divider } from 'antd';
import { CustomerServiceOutlined, PhoneOutlined, LaptopOutlined, ShakeOutlined, MailOutlined,
    SettingOutlined, ToolOutlined, ThunderboltOutlined, } from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { getAvatarColor } from '../../../utils/utils';
import { getPrioTagColor } from '../../../utils/utils';
import {fetchApi} from "../../../utils/RequestBuilder";

const { TextArea } = Input;
const { Option, OptGroup } = Select;


function RequestCreateForm({form, auth, manufacturerEngineers, syncTrigger, prefills}) {

    const { t } = useTranslation();
    const initialStatus = 'IN_PROGRESS';

    //Countries
    const [countryCount, setCountryCount] = useState(0);
    const [countries, setCountries] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const priorityCountries = ["Germany", "Austria", "Switzerland", "Italy", "France"];
    //Accounts
    const [accounts, setAccounts] = useState([]);
    const [accountCount, setAccountCount] = useState(0);
    const [accountFieldDisabled, setAccountFieldDisabled] = useState(true);
    //Asset
    const [assetCount, setAssetCount] = useState(0);
    const [assetFieldDisabled, setAssetFieldDisabled] = useState(true);
    //Contacts
    const [contactList, setContactList] = useState([]);
    const [contactFieldDisabled, setContactFieldDisabled] = useState(true);
    //Ticket
    const [assetOptions, setAssetOptions] = useState([]);
    const [selectedContact, setSelectedContact] = useState();
    //Location
    const [locationOptions, setLocationOptions] = useState([]);
    //Cases
    const [selectedCaseType, setSelectedCaseType] = useState("");
    const [selectedCaseArea, setSelectedCaseArea] = useState("");

    const [caseOptions, setCaseOptions] = useState(false);
    const [isCaseFieldsDisabled, setIsCaseFieldsDisabled] = useState(false);
    const [isTypeFieldDisabled, setIsTypeFieldDisabled] = useState(false);
    const [caseOptionsDetails, setCaseOptionsDetails] = useState([]);

    // Countries
    async function fetchCountries() {
        try {
            const data = await fetchApi(auth, "/requests/countries");
            if (data.countries !== undefined) {
                setCountries(data.countries);
                setCountryCount(data.countries_count);
            } else {
                console.log("Salesforce Connection Error")
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchCountries()
        onCustomerChanged(prefills.customer_id, true)
        onAssetChanged(prefills.asset.id)
    }, [auth, syncTrigger]);

    useEffect(() => {
        // Set default country
        const preSelectedCountry = prefills.customer_country
        // Check if option exists
        if (countries.find(country => country.country_name === preSelectedCountry)) {
            form.setFieldsValue({country: preSelectedCountry});
            onCountryChanged(preSelectedCountry)
        }

        // Create list of priority countries
        const priorityOptions = countries
            .filter(country => priorityCountries.includes(country.country_name))
            .map(country => (
                <Select.Option key={country.country_name} value={country.country_name} label={country.country_name}>
                    {country.country_name} ({country.country_account_count})
                </Select.Option>
            ));
        
        const regularOptions = countries
            .filter(country => !priorityCountries.includes(country.country_name))
            .map(country => (
                <Select.Option key={country.country_name} value={country.country_name} label={country.country_name}>
                    {country.country_name} ({country.country_account_count})
                </Select.Option>
            ));

        setCountryOptions(
            [
                {
                    label: t('requests.forms.create.favourites'),
                    options: priorityOptions,
                },
                {
                    label:  t('requests.forms.create.all-countries'),
                    options: regularOptions,
                }
            ]
        )
    }, [countries])

    async function onCountryChanged(value) {
        if (!value) {
            setAccounts([]);
            setAccountCount(0);
            setAccountFieldDisabled(true);
            setAssetFieldDisabled(true);

            form.resetFields(['country', 'customer', 'asset', 'contact', 'area', 'caseListSelect', 'caseDetailSelect']);
        } else {
            const country = countries.find(country => country.country_name === value);
            if (country) {
                setAccountCount(country.country_account_count)
                setAccountFieldDisabled(false)

                // Fetch accounts for country
                try {
                    const accountData = await fetchApi(auth, `/requests/customers?country=${encodeURIComponent(country.country_name)}`);
                    setAccounts(accountData);
                } catch (error) {
                    console.error("Failed to fetch customers:", error);
                    setAccounts([]);
                }

            } else {
                setAccountCount(0);
                setAccounts([]);
                setAccountFieldDisabled(true);
            }
        }
    }


    // Customers
    const customerOptions = accounts.map((item) => ({
        label: (
            <Tooltip
                title={
                    <span>
                        <b>{t('requests.forms.create.city')}</b> {item.city}<br />
                    </span>
                }
                overlayStyle={{ minWidth: 300 }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{item.name}</span>
                    <Tooltip title={t('requests.forms.create.open-sf-link.customer')}>
                        <a
                            className="request-create-sf-link"
                            href={`https://abp-induction.lightning.force.com/lightning/r/Account/${item.id}/view`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: '8px' }}
                        >
                            <ToolOutlined />
                        </a>
                    </Tooltip>
                </div>
            </Tooltip>
        ),
        value: item.id,
        searchterm: item.name
    }));

    function onCustomerChanged(option) {
        // Reset all depending on fields if no customer is selected
        if (!option) {
            onAssetChanged(null);
            setSelectedContact(null);
            buildAssetOptions([]);
            setContactList([]);
            setAssetFieldDisabled(true);
            setContactFieldDisabled(true);
            setAssetCount(0);

            form.setFieldsValue({
                asset: undefined,
                caseSelect: undefined,
                contact: undefined,
                serviceSelect: undefined,
            });

            return;
        }

        if (option !== prefills.customer_id) {
            setAssetFieldDisabled(true);
            setContactFieldDisabled(true);
            onAssetChanged(null);
            setSelectedContact(null);
        }

        // Fetch assets and contacts for the selected account
        (async () => {
            try {
                const [assetsData, contactsData] = await Promise.all([
                    fetchApi(auth, `/requests/customers/${option}/assets`),
                    fetchApi(auth, `/requests/customers/${option}/contacts`)
                ]);

                buildAssetOptions(assetsData);
                setContactList(contactsData);

                setAssetCount(assetsData.length);

                setAssetFieldDisabled(false);
                setContactFieldDisabled(false);
            } catch (error) {
                console.error("Failed to fetch assets or contacts:", error);
            }
        })()
    }


    // Assets
    const onAssetChanged = (option) => {
        if (option) {
            (async () => {
                try {
                    const data = await fetchApi(auth, `/requests/service-cases/${option}`);
                    setCaseOptions(data);
                } catch (error) {
                    console.error(error);
                }
            })()
            setIsCaseFieldsDisabled(false);
        } else {
            setIsCaseFieldsDisabled(true);
            form.setFieldsValue({
                asset: undefined,
                caseListSelect: undefined,
                caseDetailSelect: undefined,
            });
        }
    };

    function buildAssetOptions(customerAssets){
        let tmpAssets = []

        customerAssets.forEach((asset) => {
            tmpAssets.push({
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{asset.type} | {asset.electrical_mode} | {asset.power_type} | {asset.power_supply} | {asset.electrical_frequency} Hz | {asset.capacity} t</span>
                        <Tooltip title={t('requests.forms.create.open-sf-link.asset')}>
                            <a 
                                className="request-create-sf-link"
                                href={`https://abp-induction.lightning.force.com/lightning/r/Equipment__c/${asset.id}/view`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()} 
                                style={{ marginLeft: '8px' }}
                            >
                                <ToolOutlined />
                            </a>
                        </Tooltip>
                    </div>
                ),
                value: asset.id,
                application: asset.application,
                delivery_date: asset.delivery_date,
                electrical_digital_control: asset.electrical_digital_control,
                project_number: asset.project_number
            })
        })

        setAssetOptions(tmpAssets)
    }

    // Case Options
    // Need use effect to set the state after loading the options from prefilled information
    useEffect(() => {
        if (Object.keys(caseOptions).length > 0) {
            onAreaChanged(prefills.area)
            onCaseTypeChanged(prefills.case_type)
        }
    }, [caseOptions])

    // Case Options -> Area
    function onAreaChanged(value) {
        setSelectedCaseArea(value)

        if (value) {
            setIsTypeFieldDisabled(false);
        } else {
            setIsTypeFieldDisabled(true);
        }
    }

    useEffect(() => {
        updateLocationOptions()
    }, [selectedCaseArea])

    // Case Options -> Type
    function onCaseTypeChanged(value) {
        setSelectedCaseType(value)
    }

    // Case Options -> Details
    useEffect(() => {
        const cascaderData = [];

        if (selectedCaseArea !== "" && selectedCaseType.toLowerCase() !== "" && Object.keys(caseOptions).length > 0) {
            // Navigate through super categories for fault or service
            Object.keys(caseOptions[selectedCaseArea.toLowerCase()][selectedCaseType.toLowerCase()]).forEach(superOption => {
                // Navigate through sub categories
                cascaderData.push({
                    value: superOption,
                    label: superOption,
                    children: caseOptions[selectedCaseArea.toLowerCase()][selectedCaseType.toLowerCase()][superOption].map(subOption => ({
                        value: subOption.id,
                        label: subOption.label
                    }))
                })
            })
        }

        setCaseOptionsDetails(cascaderData);
    }, [selectedCaseArea, selectedCaseType])

    // Prefilling is triggert after the options are fetched from the back end
    useEffect(() => {
        if (Object.keys(caseOptions).length > 0 && selectedCaseArea !== "" && selectedCaseType !== "") {
            Object.keys(caseOptions[selectedCaseArea.toLowerCase()][selectedCaseType.toLowerCase()]).forEach(caseKey => {
                caseOptions[selectedCaseArea.toLowerCase()][selectedCaseType.toLowerCase()][caseKey].forEach(subCase => {
                    if(subCase.id === prefills.case_detail_id) {
                        form.setFieldsValue({
                            caseDetailSelect: [caseKey, prefills.case_detail_id],
                        })
                    }
                })
            })
        }
    }, [caseOptions, selectedCaseArea, selectedCaseType]);

    // Case Options -> Location
    function updateLocationOptions() {
        const cascaderData = [];

        if (caseOptions && selectedCaseArea !== "") {
            const caseCopy = {...caseOptions};
            if (caseCopy[selectedCaseArea.toLowerCase()] && caseCopy[selectedCaseArea.toLowerCase()].location) {
                Object.keys(caseCopy[selectedCaseArea.toLowerCase()].location).forEach(locationKey => {
                    const items = caseCopy[selectedCaseArea.toLowerCase()].location[locationKey];
                    cascaderData.push({
                        value: locationKey,
                        label: locationKey,
                        children: items.map(item => ({
                            value: item.id,
                            label: item.label
                        }))
                    })
                })
            }
        }
        setLocationOptions(cascaderData);
    }

    // Prefilling is triggert after the options are fetched from the back end
    useEffect(() => {
        if (Object.keys(caseOptions).length > 0 && selectedCaseArea !== "") {
            Object.keys(caseOptions[selectedCaseArea.toLowerCase()].location).forEach(locationKey => {
                caseOptions[selectedCaseArea.toLowerCase()].location[locationKey].forEach(subLocation => {
                    if(subLocation.id === prefills.location_id) {
                        form.setFieldsValue({
                            location: [locationKey, prefills.location_id],
                        })
                    }
                })
            })
        }

    }, [caseOptions, selectedCaseArea]);


    return (
        <Form
            labelCol={{
                span: 7,
            }}
            wrapperCol={{
                span: 17,
            }}
            autoComplete="off"
            name="basic"
            form={form}
            initialValues={{
                area: prefills.area,
                asset: prefills.asset.id,
                caseType: prefills.case_type,
                comment: prefills.comment_internal,
                contact: prefills.responsible_contact_id,
                customer: prefills.customer_id,
                description: prefills.description,
                manufacturerEngineerSelect: prefills.manufacturer_engineer_id,
                priority: prefills.priority,
                serviceTypeIncoming: prefills.service_type_incoming,
                serviceTypeProcessing: prefills.service_type_processing,
                ticketStatus: initialStatus,
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label={t('requests.forms.create.country')}
                        name="country"
                        rules={[{
                            required: true,
                            message: t('requests.forms.create.country') + ' ' + t('requests.forms.create.error-message')
                        }]}
                    >
                        <Select
                            placeholder={`${t('requests.forms.create.placeholder.country')} (${countryCount})`}
                            showSearch
                            filterOption={(input, option) =>
                                option?.value?.toString().toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={onCountryChanged}
                        >
                            {countryOptions.map(group => (
                                <OptGroup key={group.label} label={group.label}>
                                    {group.options}
                                </OptGroup>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.customer')}
                        name="customer"
                        rules={[{
                            required: true,
                            message: t('requests.forms.create.customer') + ' ' + t('requests.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={customerOptions}
                            onChange={onCustomerChanged}
                            placeholder={`${t('requests.forms.create.placeholder.customer')} (${accountCount})`}
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            optionFilterProp="children"
                            disabled={accountFieldDisabled}
                        />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        name="asset"
                        label={t('tickets.forms.create.asset')}
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.asset') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                    >
                        <Select
                            showSearch
                            options={(assetOptions || []).map(equipment => ({
                                label: (
                                    <Tooltip
                                        title={
                                            <span>
                                                <b>{t('requests.forms.create.application')}</b> {equipment.application}<br />
                                                <b>{t('requests.forms.create.delivery-date')}</b> {equipment.delivery_date}<br />
                                                <b>{t('requests.forms.create.control')}</b> {equipment.electrical_digital_control}<br />
                                                <b>{t('requests.forms.create.project-number')}</b> {equipment.project_number}<br />
                                            </span>
                                        }
                                        overlayStyle={{ minWidth: 300 }}
                                    >
                                        {equipment.label}
                                    </Tooltip>
                                ),
                                value: equipment.value
                            }))}
                            onChange={onAssetChanged}
                            placeholder={`${t('requests.forms.create.placeholder.asset')} (${assetCount})`}
                            disabled={assetFieldDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.area')}
                        name="area"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.create.labels.area') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={[
                                {
                                    value: "MECHANICAL",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <SettingOutlined />
                                            <span style={{marginLeft: "4px"}}>{t('requests.forms.create.mechanical')}</span>
                                        </div>
                                    )
                                },
                                {
                                    value: "ELECTRICAL",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <ThunderboltOutlined />
                                            <span style={{marginLeft: "4px"}}>{t('requests.forms.create.electrical')}</span>
                                        </div>
                                    )
                                },

                            ]}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.area')}
                            disabled={isCaseFieldsDisabled}
                            onChange={onAreaChanged}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.type')}
                        name="caseType"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Select
                            options={[
                                {
                                    value: "FAULT",
                                    label: t('requests.forms.create.fault')

                                },
                                {
                                    value: "SERVICE",
                                    label: t('requests.forms.create.service')
                                },

                            ]}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.type')}
                            onChange={onCaseTypeChanged}
                            disabled={isTypeFieldDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.details')}
                        name="caseDetailSelect"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Cascader
                            options={caseOptionsDetails}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.details')}
                            disabled={isCaseFieldsDisabled}
                            showSearch={
                                (inputValue, path) =>
                                    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.location')}
                        name="location"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Cascader
                            options={locationOptions}
                            placeholder={t('requests.forms.create.placeholder.location')}
                            disabled={isCaseFieldsDisabled}
                            showSearch={
                                (inputValue, path) =>
                                    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
                            }
                        />
                    </Form.Item>
                    <Divider />
                    <Form.Item
                        name="contact"
                        label={t('requests.forms.create.contact')}
                        rules={[{
                            required: true,
                            message: t('requests.forms.create.contact') + ' ' + t('requests.forms.create.error-message')
                        }]}
                        shouldUpdate
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            optionFilterProp="children"
                            onChange={(value) => setSelectedContact(value)}
                            key={selectedContact}
                            placeholder={t('requests.forms.create.placeholder.contact')}
                            disabled={contactFieldDisabled}
                            options={contactList.map(user => ({
                                label: (
                                    <Tooltip
                                        title={
                                            <span>
                                                <b>{t('requests.forms.create.function')}</b> {user.function}<br />
                                                <b>{t('requests.forms.create.mail')}</b> {user.email}<br />
                                                <b>{t('requests.forms.create.phone')}</b> {user.phone}<br />
                                                <b>{t('requests.forms.create.mobile')}</b> {user.mobile_phone}<br />
                                            </span>
                                        }
                                        overlayStyle={{ minWidth: 300 }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar style={{ backgroundColor: getAvatarColor(user.signature), marginRight: '8px' }}>
                                                    {user.signature}
                                                </Avatar>
                                                {user.name}
                                            </div>
                                            <Tooltip title={t('requests.forms.create.open-sf-link.contact')}>
                                                <a 
                                                    className="request-create-sf-link"
                                                    href={`https://abp-induction.lightning.force.com/lightning/r/Contact/${user.id}/view`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) => e.stopPropagation()} 
                                                    style={{ marginLeft: '8px' }}
                                                >
                                                    <ToolOutlined />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </Tooltip>
                                ),
                                value: user.id,
                                searchterm: user.name
                            }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.description')}
                        name="description"
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.description') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                    >
                        <TextArea
                            rows={4}
                            placeholder={t('requests.forms.create.placeholder.description')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.ticket-status')}
                        name="ticketStatus"
                        rules={[{ required: true }]}
                    >
                        <Select
                            style={{}}
                            value={'OPEN'}
                            options={[
                                {
                                    value: 'OPEN',
                                    label: <Badge status="warning" text="Requested" />
                                },
                                {
                                    value: 'IN_PROGRESS',
                                    label: <Badge color="#1677FF" text="In Progress" />
                                },
                                {
                                    value: 'CLOSED',
                                    label: <Badge status="success" text="Done" />
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.type-incoming')}
                        name="serviceTypeIncoming"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.create.labels.type-incoming') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={[
                                {
                                    value: "HOTLINE",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <PhoneOutlined />
                                            <span style={{marginLeft: "4px"}}>Hotline</span>
                                        </div>
                                    )
                                },
                                {
                                    value: "DIRECT",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <ShakeOutlined />
                                            <span style={{marginLeft: "4px"}}>Direktanruf</span>
                                        </div>
                                    )
                                },
                                {
                                    value: "MAIL",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <MailOutlined />
                                            <span style={{marginLeft: "4px"}}>Mail</span>
                                        </div>
                                    )
                                }
                            ]}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.type-incoming')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.create.labels.type-processing')}
                        name="serviceTypeProcessing"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.create.labels.type-processing') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            options={[
                                {
                                    value: "PHONE",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <PhoneOutlined />{" / "}<MailOutlined style={{marginLeft: "4px"}} />
                                            <span style={{marginLeft: "4px"}}>Phone/Mail</span>
                                        </div>
                                    )
                                },
                                {
                                    value: "REMOTE",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <LaptopOutlined />
                                            <span style={{marginLeft: "4px"}}>Remote</span>
                                        </div>
                                    )
                                },
                                {
                                    value: "EOD",
                                    label: (
                                        <div style={{ display: "flex", alignItems: "center"}}>
                                            <CustomerServiceOutlined/>
                                            <span style={{marginLeft: "4px"}}>EoD</span>
                                        </div>
                                    )
                                }
                            ]}
                            placeholder={t('tickets.details.services-area.forms.create.placeholder.type-processing')}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer')}
                        name="manufacturerEngineerSelect"
                        rules={[{
                            required: true,
                            message: t('tickets.details.services-area.forms.edit.labels.manufacturer-engineer') + ' ' + t('tickets.details.services-area.forms.create.error-message')
                        }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('requests.forms.create.placeholder.service-engineer')}
                        >
                            {
                                (manufacturerEngineers || []).map((engineer) => (
                                    <Option key={engineer.id} value={engineer.id} searchterm={engineer.name}>
                                        <Avatar style={{ backgroundColor: getAvatarColor(engineer.signature) }}>
                                            {engineer.signature}
                                        </Avatar>  {engineer.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('tickets.forms.create.priority')}
                        name="priority"
                        rules={[{
                            required: true,
                            message: t('tickets.forms.create.priority') + ' ' + t('tickets.forms.create.error-message')
                        }]}
                    >
                        <Select
                            style={{}}
                            placeholder={t('requests.forms.create.placeholder.priority')}
                            options={[
                                {
                                    value: 'MINOR',
                                    label: <Tag color={getPrioTagColor('MINOR')}>MINOR</Tag>
                                },
                                {
                                    value: 'MAJOR',
                                    label: <Tag color={getPrioTagColor('MAJOR')}>MAJOR</Tag>
                                },
                                {
                                    value: 'CRITICAL',
                                    label: <Tag color={getPrioTagColor('CRITICAL')}>CRITICAL</Tag>
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('requests.forms.create.comment-internal')}
                        name="comment"
                    >
                        <TextArea
                            rows={3}
                            placeholder={t('requests.forms.create.placeholder.comment-internal')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default RequestCreateForm;
