import { Button, Select, Space, notification } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApi, payloadApi } from "../../../utils/RequestBuilder";

function AssignmentForm ({assetTypeId, serviceType, modules, issues}) {
    const { t } = useTranslation();

    const [formattedIssues, setFormattedIssues] = useState([])
    const [formattedModules, setFormattedModules] = useState([])
    const [selectedIssues, setSelectedIssues] = useState([])
    const [selectedModuleForIssue, setSelectedModuleForIssue] = useState(0)
    const [selectedModules, setSelectedModules] = useState([])
    const [modulesForIssue, setModulesForIssues] = useState([])

    async function loadAssignedModules(auth) {
        if (assetTypeId > 0) {
            try {
                const result = await getApi(auth,
                    process.env.REACT_APP_MANU_MANAGEMENT_URL + "/assign/asset-types/" +assetTypeId + "/service/" + serviceType);
                let resultModules = result.modules.split(",").map(Number)
                if (resultModules.length > 0 && resultModules[0] !== 0) {
                    setSelectedModules(resultModules)
                } else {
                    setSelectedModules([])
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function loadAssignedIssues(auth) {
        if (assetTypeId > 0) {
            try {
                const result = await getApi(auth,
                    process.env.REACT_APP_MANU_MANAGEMENT_URL +
                    "/assign/asset-types/" +assetTypeId + "/service/" + serviceType + "/modules/" + selectedModuleForIssue);
                let resultIssues = result.issues.split(",").map(Number)
                if (resultIssues.length > 0 && resultIssues[0] !== 0) {
                    setSelectedIssues(resultIssues)
                } else {
                    setSelectedIssues([])
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function handleUpdate(auth, payload, path) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + path,
                "PUT",
                payload
            );
            return result.status
        } catch (error) {
            console.log(error)
        }
    }

    const handleIssuesChange = (value) => {
        setSelectedIssues(value)
    };

    const handleModulesChange = (value) => {
        setSelectedModules(value)
    };

    const handleModuleForIssuesChange = (value) => {
        setSelectedModuleForIssue(value)
    };

    //Show Entry Save Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showCreateAlert(response, field) {
        let desc = "";
        let title = "";
        let errorCode = "";
        switch (field) {
            case "modules": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.modules.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.modules.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.modules.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.modules.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.asset-type.modules.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            case "issues": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.issues.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.issues.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.issues.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.issues.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.asset-type.issues.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            default:
                break;
        }
    }

    async function onUpdateIssues() {
        const status = await handleUpdate("", {
                issues: selectedIssues
            },
            "/assign/asset-types/" + assetTypeId + "/service/" + serviceType +"/modules/" + selectedModuleForIssue + "/bulk"
        )
        showCreateAlert(status, "issues")
    }

    async function onUpdateModules() {
        const status = await handleUpdate("", {
            modules: selectedModules
        },
            "/assign/asset-types/" + assetTypeId + "/service/" + serviceType +"/bulk"
        )
        showCreateAlert(status, "modules")
    }

    useEffect(() => {
        let formatted = []
        issues.forEach((issue) => {
            formatted.push({
                key: issue.id,
                value: issue.id,
                label: issue.en,
                searchterm: issue.en
            })
        })

        setFormattedIssues(formatted)
        // eslint-disable-next-line
    }, [issues])

    useEffect(() => {
        let formatted = []
        modules.forEach((module) => {
            formatted.push({
                key: module.id,
                value: module.id,
                label: module.en,
                searchterm: module.en
            })
        })

        setFormattedModules(formatted)
        // eslint-disable-next-line
    }, [modules])

    useEffect(() => {
        let optionsList = []
        modules.forEach((module) => {
            if (selectedModules.includes(module.id)) {
                optionsList.push({
                    label: module.en,
                    value: module.id,
                    searchterm: module.en
                })
            }
        })
        setModulesForIssues(optionsList)

        // eslint-disable-next-line
    }, [selectedModules]);

    useEffect(() => {
        loadAssignedModules()
        // eslint-disable-next-line
    }, [assetTypeId, serviceType])

    useEffect(() => {
        loadAssignedIssues()
        // eslint-disable-next-line
    }, [selectedModuleForIssue])

    return (
        <>
            {contextHolder}
            <div>
                <Space direction="vertical" style={{marginTop: "16px"}}>
                    <Space>
                        <b>{t('administration.asset-types.form.modules')}:</b>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                minWidth: 400,
                            }}
                            onChange={handleModulesChange}
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            options={formattedModules}
                            value={selectedModules}
                            placeholder={t('administration.asset-types.form.placeholder.modules')}
                        />
                        <Button
                            onClick={() => onUpdateModules()}
                        >
                            <SaveOutlined />{t('administration.asset-types.form.edit.modules')}
                        </Button>
                    </Space>
                    <Space>
                        <p><b>{t('administration.asset-types.form.issues')}:</b></p>
                        <Select
                            style={{
                                minWidth: 160,
                            }}
                            onChange={handleModuleForIssuesChange}
                            options={modulesForIssue}
                            placeholder={t('administration.asset-types.form.placeholder.module')}
                        />
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                minWidth: 300,
                            }}
                            onChange={handleIssuesChange}
                            options={formattedIssues}
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            value={selectedIssues}
                            placeholder={t('administration.asset-types.form.placeholder.issues')}
                        />
                        <Button
                            onClick={() => onUpdateIssues()}
                            ><SaveOutlined />{t('administration.asset-types.form.edit.issues')}
                        </Button>
                    </Space>
                </Space>
            </div>
        </>
    )
}

export default AssignmentForm