import { Select} from "antd";
import {useTranslation} from "react-i18next";

const { Option } = Select;

function CaseFilter({cases}) {

    const { t } = useTranslation();

    return (
        
        <Select
            //value={value}
            showSearch
            allowClear
            width='160'
            placeholder={t('requests.overview.filter.case')}
            optionFilterProp="children"
            //onChange={onChange}
            style={{minWidth: "150px"}}
            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
            options={cases}
        >
            {/* {
                (customers || []).map((customer) => {
                    return(
                        <Option key={customer.id} value={customer.id} searchterm={customer.name}>
                            {customer.name}
                        </Option>);
                })
            } */}
        </Select>
        
    )
}

export default CaseFilter