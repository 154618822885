import { Button, Checkbox, Input, Tabs, Space, Form, notification } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApi, payloadApi } from "../../../utils/RequestBuilder";
import AssignmentForm from "./AssignmentForm";

function AssetTypeForm({id, modules, issues, callbackFunc}) {
    const { t } = useTranslation();

    const [nameForm] = Form.useForm()
    const [serviceForm] = Form.useForm()
    const [assetType, setAssetType] = useState({
        id: 0,
        name: "",
        service_guided_enabled: false,
        service_onsite_enabled: false,
        service_remote_enabled: false,
    })

    //Show Entry Save Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    async function loadAssetType(auth) {
        if (id > 0) {
            try {
                const result = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + id);
                setAssetType(result)
            } catch (error) {
                console.log(error)
            }
        }
    }

    async function handleUpdate(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + id,
                "PUT",
                payload
            );
            callbackFunc()
            return result.status
        } catch (error) {
            console.log(error)
        }
    }

    function showCreateAlert(response, field) {
        let desc = "";
        let title = "";
        let errorCode = "";
        switch (field) {
            case "name": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.name.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.name.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.name.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.name.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.name.asset-type.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            case "services": {
                if (response === 200) {
                    title = t('administration.asset-types.form.edit.asset-type.services.save-message.title.success');
                    desc = t('administration.asset-types.form.edit.asset-type.services.save-message.body.success');
                    openNotification('topRight', 'success', desc, title, errorCode)
                } else {
                    title = t('administration.asset-types.form.edit.asset-type.services.save-message.title.error');
                    desc = t('administration.asset-types.form.edit.asset-type.services.save-message.body.error');
                    errorCode = '[' + t('administration.asset-types.form.edit.services.asset-type.save-message.status-code') + ': ' + response + ']'
                    openNotification('topRight', 'error', desc, title, errorCode)
                }
            }
                break;
            default:
                break;
        }
    }

    async function onEditName() {
        let asset = assetType
        const formValues = nameForm.getFieldsValue();
        asset.name = formValues.name
        const status = await handleUpdate("",asset)
        showCreateAlert(status, "name")

        
    }

    async function onEditService() {
        let asset = assetType
        const formValues = serviceForm.getFieldsValue();
        asset.service_guided_enabled = formValues.guided
        asset.service_onsite_enabled = formValues.onsite
        asset.service_remote_enabled = formValues.remote
        const status = await handleUpdate("", asset)
        showCreateAlert(status, "services")
    }

    useEffect(() => {
        nameForm.setFieldsValue({
            name: assetType.name,
        })
        serviceForm.setFieldsValue({
            guided: assetType.service_guided_enabled,
            onsite: assetType.service_onsite_enabled,
            remote: assetType.service_remote_enabled,
        })
        // eslint-disable-next-line
    }, [assetType])

    useEffect(() => {
        loadAssetType()
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            {contextHolder}
            <div>
                <h3 style={{marginTop: "8px"}}>{t('administration.asset-types.title')}</h3>
                <br />
                <Space direction="vertical" size="large">
                    <Space size="middle">
                        <b>{t('administration.asset-types.form.name')}:</b>
                        <Form
                            autoComplete="off"
                            name="basic"
                            form={nameForm}
                        >
                            <Form.Item
                                name="name"
                                rules={[{
                                    required: true,
                                }]}
                                style={{marginBottom: "0px"}}
                            >
                                <Input style={{minWidth: "350px"}} placeholder={t('administration.asset-types.form.placeholder.name')}/>
                            </Form.Item>
                        </Form>
                        <Button
                            type="primary"
                            onClick={() => onEditName()}
                        >
                            <SaveOutlined/>{t('administration.asset-types.form.edit.name')}
                        </Button>
                    </Space>
                    <Space size="middle">
                        <b>{t('administration.asset-types.form.service-types')}:</b>
                        <div>
                            <Form
                                autoComplete="off"
                                name="basic"
                                form={serviceForm}
                            >
                                <Form.Item
                                    name="guided"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>Guided Support</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="onsite"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>Onsite Visit</Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="remote"
                                    valuePropName="checked"
                                    style={{marginBottom: "0px"}}
                                >
                                    <Checkbox>Remote Service</Checkbox>
                                </Form.Item>
                            </Form>
                        </div>
                        <Button
                            type="primary"
                            onClick={() => onEditService()}
                        >
                            <SaveOutlined/>{t('administration.asset-types.form.edit.services')}
                        </Button>
                    </Space>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                key: '1',
                                label: 'Guided Service',
                                children: <AssignmentForm assetTypeId={id} serviceType={'guided'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_guided_enabled,
                            },
                            {
                                key: '2',
                                label: 'Onsite Service',
                                children: <AssignmentForm assetTypeId={id} serviceType={'onsite'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_onsite_enabled
                            },
                            {
                                key: '3',
                                label: 'Remote Service',
                                children: <AssignmentForm assetTypeId={id} serviceType={'remote'} modules={modules} issues={issues} />,
                                disabled: !assetType.service_remote_enabled
                            },
                        ]}
                        // onChange={onChange}
                    />
                </Space>
            </div>
        </>
    );
}

export default AssetTypeForm