import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Select, Space, Input, Tooltip, notification } from 'antd';
import { useState, useEffect, useContext } from "react";
import { PlusOutlined, SettingOutlined, ThunderboltOutlined, ToolOutlined } from "@ant-design/icons";
import { fetchApi } from "../../utils/RequestBuilder";
import RequestKanbanLists from "../../components/Requests/RequestsKanbanList";
import PersonFilter from "../../components/Requests/KanbanTools/PersonFilter";
import CustomerFilter from '../../components/Requests/KanbanTools/CustomerFilter';
import CaseFilter from '../../components/Requests/KanbanTools/CaseFilter';
import EquipmentFilter from '../../components/Tickets/KanbanTools/EquipmentFilter';
import Sort from "../../components/Requests/KanbanTools/Sort";
import {useAuth} from "react-oidc-context";
import AddRequestModal from '../../components/Requests/AddRequestModal';
import { GlobalContext } from "../../utils/GlobalContext";
import { userHasRole, USER_ROLE_MANUFACTURER_ENGINEER } from "../../utils/userManagement";


const { Search } = Input;


function Requests() {

    const navigate = useNavigate()

    //auth
    const auth = useAuth();
    const [user, setUser] = useState({sub: ""})
    const [hasServiceManagerRole, setRole] = useState(false);

    //Initial state
    const [ tickets, setTickets ] = useState([]);
    //Unfiltered Ticket Lists
    const [ openTickets, setOpenTickets ] = useState([]);
    const [ progressTickets, setProgressTickets ] = useState([]);
    const [ closedTickets, setClosedTickets ] = useState([]);
    //Filtered Ticket Lists
    const [ filteredOpenTickets, setFilteredOpenTickets] = useState([]);
    const [ filteredProgressTickets, setFilteredProgressTickets ] = useState([]);
    const [ filteredClosedTickets, setFilteredClosedTickets ] = useState([]);

    const [ addTicketModalIsOpen, setTicketModalIsOpen ] = useState(0);

    const [ users, setUsers ] = useState([]);
    const [ customers, setCustomers ] = useState([])
    const [ customersMap, setCustomersMap ] = useState([])

    const [ assets, setAssets ] = useState([]);
    const [ assetFilterArray, setAssetFilterArray ] = useState([]);

    const [ sortOrder, setSortOrder] = useState(1);
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ userFilter, setUserFilter ] = useState("");
    const [ userFilterValue, setUserFilterValue ] = useState(undefined);
    const [ customerFilter, setCustomerFilter ] = useState("");
    const [ customerFilterValue, setCustomerFilterValue ]= useState(undefined);
    const [ equipmentFilter, setEquipmentFilter ] = useState("");
    const [ equipmentFilterValue, setEquipmentFilterValue ] = useState(undefined);
    const [ equipmentFilterDisabled, setEquipmentFilterDisabled ] = useState(true);

    const  notificationContext  = useContext(GlobalContext);
    const [api, contextHolder] = notification.useNotification();

    //Localisation
    const { t } = useTranslation();

    const cases= [
        {
            value: 1,
            label: (
                <div style={{ display: "flex", alignItems: "center"}}>
                    <SettingOutlined />  
                    <span style={{marginLeft: "4px"}}>Mechanisch</span> 
                </div>
            ),
            searchterm: "mechanisch"
        },
        {
            value: 2,
            label: (
                <div style={{ display: "flex", alignItems: "center"}}>
                    <ThunderboltOutlined /> 
                    <span style={{marginLeft: "4px"}}>Elektrisch</span> 
                </div>
            ),
            searchterm: "elektrisch"
        },
        {
            value: 3,
            label: (
                <div style={{ display: "flex", alignItems: "center"}}>
                    <ToolOutlined/> 
                    <span style={{marginLeft: "4px"}}>Werkstatt</span> 
                </div>
            ),
            searchterm: "werkstatt"
        }
    ];


    //Open Modal for ticket creation
    const openTicketModal = () => {
        setTicketModalIsOpen(addTicketModalIsOpen + 1)
    };

    //Fetch tickets from Server
    async function loadTickets() {
        try {
            const jsonData = await fetchApi(auth, "/customers/tickets")
            setTickets(jsonData);
        } catch (error) {
            // Handle error
        }
    }

     //Fetch users from Server
    async function loadUsers() {
        try {
            const jsonData = await fetchApi(auth, "/manufacturer-engineers")
            setUsers(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Fetch customers from server
    async function loadCustomers() {
        try {
            const jsonData = await fetchApi(auth, "/customers")
            setCustomers(jsonData)

            let customerMap = []
            jsonData.forEach((customer) => customerMap[customer.id] = customer.name)
            setCustomersMap(customerMap)

        } catch (error) {
            // Handle error
        }
    }

    //Fetch assets based on selected customer
    async function loadCustomerAssets(customerId) {
        try {
            const jsonData = await fetchApi(auth, `/customers/${customerId}/assets`);
            setAssets(jsonData);
        } catch (error) {
            // Handle error
        }
    }

    //Create asset filter array from fetched assets
    function createAssetFilter (assetList){
        return assetList.map((item) => {
            return {
                label: item.type + " - " + item.project_number,
                value: item.id,
            };
        });
    }

    // Check if user filter is present as GET parameter
    async function getDefaultUser() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('user');

        if (userId != null) {
            users.forEach((user) => {
                if (user.id === userId) {
                    setUserFilter(user.name)
                    setUserFilterValue(user.id)
                }
                
            })
        }
    }

    // Check if customer filter is present as GET parameter
    async function getDefaultCustomer() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const customerId = params.get('customer');

        if (customerId != null) {
            customers.forEach((customer) => {
                if (customer.id === customerId) {
                    setCustomerFilter(customer.name)
                    setCustomerFilterValue(customer.id)
                }
                
            })
        }
    }

    // Check if asset filter is present as GET parameter
    async function getDefaultAsset() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const assetId = params.get('asset');

        if (assetId != null) {

            assets.forEach((workstation) => {
                workstation.assets.forEach((asset) => {
                    if (asset.id === assetId) {
                        setEquipmentFilter(asset.name)
                        setEquipmentFilterValue(asset.id)
                    }
                })
            })
        }
    }

    function sortAllTickets() {
        sortTickets(openTickets, sortOrder, 'OPEN');
        sortTickets(progressTickets, sortOrder, 'IN_PROGRESS');
        sortTickets(closedTickets, sortOrder, 'CLOSED');
    }

    function customFilterFunction(inputArray, column) {
        let filteredStatusArray = [...inputArray];
        //Apply customer filter
        if (customerFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.customer_name.toLowerCase() === customerFilter.toLowerCase()
            )
        }
        //Apply user filter
        if (userFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) => {
                return item.manufacturer_engineer_name.toLowerCase() === userFilter.toLowerCase()}
            )
        }
        // Apply asset filter
        if (equipmentFilter !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) => {
                return item.asset.id === equipmentFilterValue}
            );
        }
        //Apply text search filter
        if (searchTerm !== "" && filteredStatusArray.length > 0) {
            filteredStatusArray = (filteredStatusArray || []).filter((item) =>
                item.asset.type_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.asset.project_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.case_detail.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase())
            )
        }

        switch (column) {
            case 1:
                setFilteredOpenTickets(filteredStatusArray);
                break;
            case 2:
                setFilteredProgressTickets(filteredStatusArray);
                break;
            case 3:
                setFilteredClosedTickets(filteredStatusArray);
                break;
            default:
                break;
        }
    }

    function onSearchFieldChange(event) {
        if (event.target.value !== "") {
            setSearchTerm(event.target.value)
        }
    }

    function onCustomerFilterChange(value, option) {
        setCustomerFilter(option?.searchterm ?? '')
        setCustomerFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('customer', value);
        navigate(`?${searchParams.toString()}`);

        // Enable Equipment Filter when a customer is selected and load assets
        if (value) {
            setEquipmentFilterDisabled(false);
            loadCustomerAssets(value);
        } else {
            setEquipmentFilterDisabled(true);
            setAssets([]);
        }
    }

    function onUserFilterChange(value, option) {
        setUserFilter(option?.searchterm ?? '')
        setUserFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('user', value);
        navigate(`?${searchParams.toString()}`);
    }

    function onEquipmentFilterChange(value, option) {
        setEquipmentFilter(option?.label ?? '')
        setEquipmentFilterValue(value)
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('asset', value);
        navigate(`?${searchParams.toString()}`);
    }

    useEffect(() => {
    }, [userFilter])

    function sortTickets(ticketList, method, status) {
        let sortedTickets = [...ticketList]
        switch (method) {
            case 1: //Updated at desc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);
                    return dateB - dateA
                });
                break;
            case 2: //Updated at asc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.updated_at);
                    const dateB = new Date(b.updated_at);
                    return dateA - dateB
                });
                break;
            case 3: // Created at desc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    return dateB - dateA;
                });
                break;
            case 4: // Created at asc
                (sortedTickets || []).sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    return dateA - dateB;
                });
                break;
            case 5: //Alphabetically desc
                (sortedTickets || []).sort((a,b) => {
                    return (b.area + " " + b.case_detail).localeCompare(a.area + " " + a.case_detail)
                });
                break;
            case 6: //Alphabetically asc
                (sortedTickets || []).sort((a,b) => {
                    return (a.area + " " + a.case_detail).localeCompare(b.area + " " + b.case_detail)
                });
                break;
            case 7: //By Priority desc
                (sortedTickets || []).sort((a,b) => {
                    const priorityMap = { 'CRITICAL': 3, 'MAJOR': 2, 'MINOR': 1 };
                    return priorityMap[b.priority] - priorityMap[a.priority];
                });
                break;
            case 8: //By Priority asc
                (sortedTickets || []).sort((a,b) => {
                    const priorityMap = { 'CRITICAL': 3, 'MAJOR': 2, 'MINOR': 1 };
                    return priorityMap[a.priority] - priorityMap[b.priority];
                });
                break;
            default:
                break;
        }
        switch (status) {
            case 'OPEN':
                setOpenTickets(sortedTickets);
                setFilteredOpenTickets(sortedTickets);
                break;
            case 'IN_PROGRESS':
                setProgressTickets(sortedTickets);
                setFilteredProgressTickets(sortedTickets);
                break;
            case 'CLOSED':
                setClosedTickets(sortedTickets);
                setFilteredClosedTickets(sortedTickets);
                break;
            default:
                break;
        }
    }

    //Create Array for specific Ticket status
    function getStatusArray(data, status) {
        let statusArray = [];
        if ( data !== undefined) {
            statusArray = (data || []).filter((element) => element.status === status)
        }
        return statusArray;
    }

    function onSortChange(order) {
        setSortOrder(order)
    }

    //Handle Change of any filter
    useEffect(() => {
        customFilterFunction(openTickets, 1)
        customFilterFunction(progressTickets, 2)
        customFilterFunction(closedTickets, 3)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, customerFilter, userFilter, equipmentFilter])

    //Initial page call
    useEffect(() => {
        if (auth.user !== undefined) {
            setUser(auth.user.profile);
            setRole(userHasRole(auth.user.profile, USER_ROLE_MANUFACTURER_ENGINEER));
            loadTickets();
            loadCustomers();
            loadUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    useEffect(() => {
        if (customerFilterValue  && customerFilterValue !== undefined) {
            setEquipmentFilterDisabled(false);
            loadCustomerAssets(customerFilterValue);
        } else {
            setEquipmentFilterDisabled(true); 
            setAssets([]);
        }
    }, [customerFilterValue]);

    //Set Lists for ticket statuses
    useEffect(() => {
        setOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        setFilteredOpenTickets(() => getStatusArray(tickets, 'OPEN'));
        setFilteredProgressTickets(() => getStatusArray(tickets, 'IN_PROGRESS'));
        setFilteredClosedTickets(() => getStatusArray(tickets, 'CLOSED'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickets])

    //Handle change of sorter method
    useEffect(() => {
        sortAllTickets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder])

     //Handle Change of asset list
     useEffect(() => {
         setAssetFilterArray(createAssetFilter(assets))
         getDefaultAsset()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets])

    //Handle Change of user list
    useEffect(() => {
        getDefaultUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    //Handle Change of customer list
    useEffect(() => {
        getDefaultCustomer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers])

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipmentFilterDisabled])



    return (
        <>
            {contextHolder}
            <div>
                <div id="sub-header"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >   <Space>
                    <h2>{t('requests.overview.header')}</h2>
                    <Button
                        onClick={() => openTicketModal()}
                        type="primary">
                        <PlusOutlined />
                        {t('tickets.overview.add')}
                    </Button>
                </Space>

                    <Space>
                        <CustomerFilter
                            value={customerFilterValue}
                            customers={customers}
                            onChange={onCustomerFilterChange}
                        />
                        <Tooltip title={equipmentFilterDisabled ? t('requests.overview.filter.equipment-tooltip') : ''} placement="top">
                            <div>
                                <EquipmentFilter 
                                    value={equipmentFilterValue}
                                    onChange={onEquipmentFilterChange}
                                    options={assetFilterArray}
                                    disabled={equipmentFilterDisabled}
                                />
                            </div>
                        </Tooltip>
                        {/*<CaseFilter
                            //value={customerFilterValue}
                            cases={cases}
                            //onChange={onCustomerFilterChange}
                        />*/}
                        <PersonFilter
                            value={userFilterValue}
                            onChange={onUserFilterChange}
                            users={users}
                        />
                        <Sort
                            setSortOrder={onSortChange}
                        />
                        <Search
                            style={{
                                width: 200,
                            }}
                            placeholder={t('requests.overview.filter.search')}
                            onChange={onSearchFieldChange}
                            enterButton
                        />
                    </Space>
                </div>
                <div id="sub-content">
                    <Row className="tickets-kanban" gutter={16}>
                        <RequestKanbanLists
                            tickets={filteredOpenTickets}
                            header={t('tickets.status.open')}
                            hasManufacturerRole={hasServiceManagerRole}
                            customers={customersMap}
                        />
                        <RequestKanbanLists
                            tickets={filteredProgressTickets}
                            header={t('tickets.status.in-progress')}
                            hasManufacturerRole={hasServiceManagerRole}
                            customers={customersMap}
                        />
                        <RequestKanbanLists
                            tickets={filteredClosedTickets}
                            header={t('tickets.status.closed')}
                            hasManufacturerRole={hasServiceManagerRole}
                            customers={customersMap}
                        />
                    </Row>
                </div>
                <AddRequestModal
                    isOpen={addTicketModalIsOpen}
                    auth={auth}
                    customers={customers}
                    manufacturerEngineers={users}
                    callbackFct={loadTickets}
                />
            </div>
        </>
    );
}

export default Requests;
