import {Col, List, Space} from "antd";
import {Scrollbars} from "react-custom-scrollbars-2";
import TicketKanbanCard from "../Tickets/TicketKanbanCard";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

function RequestKanbanLists({tickets, header, hasManufacturerRole, customers}) {

    const { t } = useTranslation();

    return (
        <Col className="tickets-kanban-column" span={8}>
            <Space className="tickets-status-columns-header"
                   style={{
                       display: 'flex',
                       justifyContent: 'space-between',

                   }}
            >
                <h3>{header} <span>({tickets.length} {t('requests.title')})</span></h3>
            </Space>
            <div className="border-radius-setting"
                 style={{
                     paddingBottom: "15px",
                     paddingTop: "8px",
                     backgroundColor: "#f5f5f5",
                     

                 }}
            >
                <Scrollbars
                    autohide="true"
                    style={{
                        height: `calc(100vh - 260px)`,
                    }}>
                    <List
                        className={"ticket-list"}
                        split='false'
                        dataSource={tickets}
                        height={750}
                        renderItem={(item)=> (
                            <List.Item key={item.id}>
                                <TicketKanbanCard
                                    ticket={item}
                                />
                            </List.Item>
                        )}
                    />
                </Scrollbars>
            </div>
        </Col>
    )
}
export default RequestKanbanLists;
