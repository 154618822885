import {Avatar, Descriptions, Badge, Tag, Select, Form, Button, Tooltip, Input, notification, Row, Col} from "antd"
import {
    HomeOutlined,
    SelectOutlined,
    EditOutlined,
    SaveOutlined,
    CloseOutlined,
    PlusOutlined,
    LinkOutlined
} from "@ant-design/icons"
import {useTranslation} from "react-i18next"
import React, { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import {getDateFormat, getAvatarColor, getCaseTypeIcon} from "../../../utils/utils"
import { getPrioTagColor } from "../../../utils/utils"
import { changeTicketInfo, changeTicketOrderNoInfo, fetchApi } from "../../../utils/RequestBuilder"
import { GlobalContext } from "../../../utils/GlobalContext"

dayjs.extend(duration)
dayjs.extend(relativeTime)

function timeFromCreation(created_at, closed_at) {
    var a = dayjs()
    if (closed_at === "0001-01-01T00:00:00Z") {
        return dayjs(created_at).from(a, true)
    } else {
        return dayjs(created_at).to(closed_at, true)
    }
} 


function getActiveTimeTagColor(status, created_at, closed_at) {
    if (status == 'CLOSED' && closed_at !== "0001-01-01T00:00:00Z") {
        return 'grey'
    } else {
        return 'red'
    }
}


function TicketSidebarArea({asset, ticket, auth, callbackFct, hasManufacturerRole}) {

    const [ form ] = Form.useForm()
    const [ ticketData, setTicketData ] = useState(ticket)
    const [ source, setSource] = useState()
    const [ users, setUsers ] = useState([])
    const [ initialStatus, setInitialStatus ] = useState("")
    const [isAddingOrderNo, setIsAddingOrderNo] = useState(false)
    const [orderNoToUpdate, setorderNoToUpdate] = useState("")
    const [bookedTime, setBookedTime] = useState({
        min: 1,
        hours: 2,
        billableMin: 3,
        billableHours: 4
    })

    const { t, i18n } = useTranslation()

    const  notificationContext  = useContext(GlobalContext)

    const { Option } = Select

    function getPrioTag(prio) {
        let title
        switch (prio) {
            case 'MINOR':
                title = <Tag color={getPrioTagColor('MINOR')}>{t('tickets.details.priority-options.minor')}</Tag>
                break
            case 'MAJOR':
                title = <Tag color={getPrioTagColor('MAJOR')}>{t('tickets.details.priority-options.major')}</Tag>
                break
            case 'CRITICAL':
                title = <Tag color={getPrioTagColor('CRITICAL')}>{t('tickets.details.priority-options.critical')}</Tag>
                break
            default:
                title = ""
                break
        }
        return title
    }

    const getSourceIcon = (type) => {
        let icon
        switch (type) {
            case "INTERNAL":
                hasManufacturerRole ? icon = <SelectOutlined /> : icon = <HomeOutlined />
                break
            case "EXTERNAL":
                hasManufacturerRole ? icon = <HomeOutlined /> : icon = <SelectOutlined />
                break
            default:
                icon = ""
        } return icon
    }

    const getSourceText = (source) => {
        let sourceText = ""
        switch (source) {
            case "INTERNAL":
                hasManufacturerRole ? sourceText = t('tickets.details.sider-area.source.external') : sourceText = t('tickets.details.sider-area.source.internal')
                break
            case "EXTERNAL": 
                hasManufacturerRole ? sourceText = t('tickets.details.sider-area.source.internal') : sourceText = t('tickets.details.sider-area.source.external')
                break
            default:
                sourceText = " - "
                break
        } return sourceText
    }

    const getStatusBadgeStatus = (ticketStatus) => {
        switch (ticketStatus) {
            case 'OPEN':
                return 'warning'
            case 'IN_PROGRESS':
                return 'processing'
            case 'CLOSED':
                return 'success'
            default:
                return 'default' // oder einen anderen Standardstatus
        }
    }

    const getStatusBadgeText = (ticketStatus) => {
        switch (ticketStatus) {
            case 'OPEN':
                return 'Open'
            case 'IN_PROGRESS':
                return 'In Progress'
            case 'CLOSED':
                return 'Closed'
            default:
                return 'default' // oder einen anderen Standardstatus
        }
    }

    //Fetch users from Server
    async function loadUsers() {
        try {
            const jsonData = await fetchApi(auth, "/users", auth)
            setUsers(jsonData)
        } catch (error) {
            // Handle error
        }
    }

    //Show Ticket Information Update Alert
    const [api, contextHolder] = notification.useNotification()
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        })
    }

    function showUpdateAlert(response) {
        let desc = ""
        let title = ""
        let errorCode = ""
        if (response == 200) {
            title = t('tickets.details.sider-area.update-message.title.success')
            desc = t('tickets.details.sider-area.update-message.body.success')
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('tickets.details.sider-area.update-message.title.error')
            desc = t('tickets.details.sider-area.update-message.body.error')
            errorCode = '[' + t('tickets.details.sider-area.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    //Update Ticket information in database
    async function updateTicketInfo(auth, path,  name, value, id) {
        if (auth.user !== undefined) {
            const attributesArray = [
                {attributeName: name, attributeValue: value}
            ]
            try {
                const result = await changeTicketInfo(auth, path, attributesArray, id)
                showUpdateAlert(result)
                if (result === 200) {
                    callbackFct()
                }
            } catch (error) {
                console.error(error)
                //showAlert(error.status)
            }
        }
    }

    //Update Order Number information in database
    async function updateOrderNo(auth, name, value, id) {
        if (auth.user !== undefined) {
            const attributesArray = [
                {attributeName: name, attributeValue: value}
            ]
            try {
                const result = await changeTicketOrderNoInfo(auth, attributesArray, id)
                showUpdateAlert(result)
                if (result === 200) {
                    callbackFct()
                    return result
                }
            } catch (error) {
                console.error(error)
                //showAlert(error.status)
                return {error}
            }
        }
    }

    function handleAddButtonClick() {
        setIsAddingOrderNo(true)
    }
    
    function handleEditButtonClick () {
        setIsAddingOrderNo(true)
    }

    async function handleSaveButtonClick()  {
        const result = await updateOrderNo(auth, "order_number", orderNoToUpdate, ticket.id)
        if (result === 200) {
            setorderNoToUpdate("")
            setIsAddingOrderNo(false)
        }
    }

    function handleCancelButtonClick () {
        setIsAddingOrderNo(false)
        setorderNoToUpdate(ticket.order_number)
    }

    function updateTimeBooking() {
        setBookedTime({
            min: ticket.booked_time.mins % 60,
            hours: Math.floor(ticket.booked_time.mins / 60),
            billableMin: ticket.booked_time.billable_mins % 60,
            billableHours: Math.floor(ticket.booked_time.billable_mins / 60)
        })
    }

    //Change ticketData onChange of Ticket
    useEffect(() => {
        setTicketData(ticket)
        setorderNoToUpdate(ticket.order_number)
        /* if (hasManufacturerRole === false) */ loadUsers()
        form.resetFields()
        /*  if (ticketData.source === "INTERNAL") {
             setSource("internal")
         } else if (ticketData.source === "EXTERNAL") {
             setSource("external")
         }     */
        if (ticket.id !== undefined) {
            updateTimeBooking()
        }
    }, [ticket])



    useEffect(() => {
        if ("event" in notificationContext) {
            switch (notificationContext.event) {
                case "ticket_updated":
                    if(notificationContext.relation_id === ticket.id) {
                        setTicketData(prevState => ({
                            ...prevState,
                            booked_time_hours: notificationContext.payload.booked_time_hours,
                            booked_time_mins: notificationContext.payload.booked_time_mins,
                        }))
                    }
                    break
                default:
                    break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationContext])
    
    return (
        <>
            {contextHolder}
            <div>
                <Form
                    form={form}
                    name="basic"
                    initialValues={{
                        status: ticket.status,
                        assigned_to: ticket.assigned_to,
                        priority: ticket.priority
                        
                    }}
                    >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Descriptions bordered column={1} size={"middle"}>
                                {hasManufacturerRole && (
                                    <Descriptions.Item label={t('tickets.details.customer')}>
                                        <a href={`https://abp-induction.lightning.force.com/lightning/r/Account/${ticketData.customer_id}/view`} target="_blank">
                                            {ticketData.customer_name} <LinkOutlined />
                                        </a>
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label={t('assets.details.customer_designation')}>{ticketData.asset.customer_designation}</Descriptions.Item>
                                {hasManufacturerRole && (
                                    <Descriptions.Item label ={t('assets.details.type')}>{ticketData.asset.type_name}</Descriptions.Item>
                                )}
                                <Descriptions.Item label={t('assets.details.project-number')}>
                                    <a href={ticketData.asset.doc_path} target="_blank">
                                        {ticketData.asset.project_number} <LinkOutlined />
                                    </a>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.equipment-comment')}>{asset.power_supply} {asset.capacity}t</Descriptions.Item>
                            </Descriptions>
                            <Descriptions bordered column={1} size={"middle"} style={{marginTop: '24px'}}>
                                <Descriptions.Item label={t('tickets.details.service-type-incoming')}>
                                    {t('tickets.details.service-type-incoming-options.' + ticket.service_type_incoming.toLowerCase())}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.service-type-processing')}>
                                    {t('tickets.details.service-type-processing-options.' + ticket.service_type_processing.toLowerCase())}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.area')}>
                                    {getCaseTypeIcon(ticket.case_type)} {t('tickets.details.area-options.' + ticket.area.toLowerCase())}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12}>
                            <Descriptions bordered column={1} size={"middle"}>
                                <Descriptions.Item label={t('tickets.details.created_by')}>
                                    {ticketData.created_by}<br/>{getSourceIcon(ticketData.source)}{' '}{getSourceText(ticketData.source)}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.contact')}>
                                    {ticket.responsible_contact_name}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.responsible') + " (ABP)"}>
                                {hasManufacturerRole ? (
                                        <Form.Item
                                            name="manufacturer_engineer"
                                            style={{
                                                marginBottom: 0
                                            }}
                                        >
                                            <Select
                                                showSearch
                                                defaultValue={ticket.manufacturer_engineer_name}
                                                onChange={(value) => updateTicketInfo(auth, "/customers/tickets/", "assigned_to_id", value, ticket.id) }
                                                placeholder={t('tickets.details.sider-area.responsible.search')}
                                                optionFilterProp="children"
                                                style={{
                                                    width: 140,
                                                }}
                                                filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                                            >
                                                {
                                                    (users || []).map((user) => {
                                                        return(
                                                            <Option key={user.id} value={user.id} searchterm={user.name}>
                                                                <Avatar style={{ backgroundColor: getAvatarColor(user.signature)}}>
                                                                    {user.signature}
                                                                </Avatar>  {user.name}
                                                            </Option>)
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        ticket.manufacturer_engineer_name === "" ? (t('requests.overview.not-assigned')) : ticket.manufacturer_engineer_name
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.priority')}>
                                    {!hasManufacturerRole ? (
                                        <Form.Item
                                            name="priority"
                                            style={{
                                                marginBottom: 0
                                            }}
                                        >
                                            <Select
                                                style={{
                                                    width: 140,
                                                }}
                                                onChange={(value) => updateTicketInfo(auth, "/customers//tickets/", "priority", value, ticket.id) }
                                                options={[
                                                    {
                                                        value: 'MINOR',
                                                        label: <Tag color={getPrioTagColor('MINOR')}>t('tickets.details.priority-options.minor')</Tag>
                                                    },
                                                    {
                                                        value: 'MAJOR',
                                                        label: <Tag color={getPrioTagColor('MAJOR')}>t('tickets.details.priority-options.major')</Tag>
                                                    },
                                                    {
                                                        value: 'CRITICAL',
                                                        label: <Tag color={getPrioTagColor('CRITICAL')}>t('tickets.details.priority-options.critical')</Tag>
                                                    }
                                                ]}
                                            >
                                                <Tag color={getPrioTagColor(ticketData.priority)}>{ticketData.priority}</Tag>
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        getPrioTag(ticketData.priority)
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.status')}>
                                    <Form.Item
                                        name="status"
                                        style={{
                                            marginBottom: 0
                                        }}
                                    >
                                        <Select
                                            style={{
                                                width: 140,
                                            }}
                                            onChange={(value) => updateTicketInfo(auth, "/customers/tickets/", "status", value, ticket.id) }
                                            options={[
                                                {
                                                    value: 'OPEN',
                                                    label: <Badge status="warning" text={t('tickets.details.status-options.open')} />
                                                },
                                                {
                                                    value: 'IN_PROGRESS',
                                                    label: <Badge color="#1677FF" text={t('tickets.details.status-options.in_progress')} />
                                                },
                                                {
                                                    value: 'CLOSED',
                                                    label: <Badge status="success" text={t('tickets.details.status-options.closed')} />
                                                }
                                            ]}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.created-at')}>
                                    {dayjs(ticketData.created_at).format(getDateFormat(i18n.language))}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.updated-at')}>
                                    {dayjs(ticketData.updated_at).format(getDateFormat(i18n.language))}
                                </Descriptions.Item>
                                {ticketData.closed_at !== "0001-01-01T00:00:00Z" && (
                                    <Descriptions.Item label={t('tickets.details.sider-area.closed-at')}>
                                        {dayjs(ticketData.closed_at).format(getDateFormat(i18n.language))}
                                    </Descriptions.Item>
                                )}
                                <Descriptions.Item label={t('tickets.details.sider-area.active-time')}>
                                    <Tag color={getActiveTimeTagColor(ticketData.status, ticketData.created_at, ticketData.closed_at)}>
                                        {timeFromCreation(ticketData.created_at, ticketData.closed_at)}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t('tickets.details.sider-area.booked-hours')}>
                                    {ticket.booked_time.mins === 0 ? (
                                       "-"
                                    ) : (
                                        <>{bookedTime.hours} {t('tickets.details.sider-area.hours')} {bookedTime.min} {t('tickets.details.sider-area.minutes')}</>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={t('tickets.details.sider-area.billable-hours')}>
                                    {ticket.booked_time.billable_mins === 0 ? (
                                        "-"
                                    ) : (
                                        <>{bookedTime.billableHours} {t('tickets.details.sider-area.hours')} {bookedTime.billableMin} {t('tickets.details.sider-area.minutes')}</>
                                    )}
                                </Descriptions.Item>
                                <Descriptions.Item label={t('tickets.details.sider-area.order-number')}>
                                    {isAddingOrderNo && hasManufacturerRole ? (
                                        <Form.Item
                                            name="order_number"
                                            style={{
                                                marginBottom: 0
                                            }}

                                        >
                                            <Input
                                                placeholder={t('tickets.details.sider-area.order-number-placeholder')}
                                                onChange={(e) => setorderNoToUpdate(e.target.value)}
                                                value={orderNoToUpdate}
                                            />
                                            <Button type="primary" onClick={handleSaveButtonClick} style={{marginTop: "8px", marginRight: "4px"}}>
                                                <SaveOutlined /> {t('tickets.details.main-area.save')}
                                            </Button>
                                            <Button onClick={handleCancelButtonClick} style={{marginTop: "8px"}}>
                                                <CloseOutlined /> {t('tickets.details.main-area.cancel')}
                                            </Button>
                                        </Form.Item>
                                    ) : (
                                        <div>
                                            {ticketData.order_number === "" && hasManufacturerRole && (
                                                <Button type="primary" onClick={handleAddButtonClick}>
                                                    <PlusOutlined /> {t('Add')}
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                        <div>
                                            {ticketData.order_number === "" && !hasManufacturerRole && (
                                                <span>-</span>
                                            )}
                                            {ticketData.order_number !== "" && !isAddingOrderNo && (
                                                <span>{ticketData.order_number}</span>
                                            )}
                                            {hasManufacturerRole && ticketData.order_number !== "" && !isAddingOrderNo && (
                                                <Button type="primary" style={{ marginLeft: '8px' }} onClick={handleEditButtonClick}>
                                                    <EditOutlined /> {t('Edit')}
                                                </Button>
                                            )}
                                        </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>  
    )
}

export default TicketSidebarArea
