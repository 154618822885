import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form, Modal, Select, notification } from "antd";
import { payloadApi, getApi } from "../../../utils/RequestBuilder";
import { CustomerServiceOutlined, CarOutlined, LaptopOutlined } from "@ant-design/icons";

function EditNotificationModal({auth, isOpen, userList, customerList, assetTypesList, modalIsOpenCallback, criteriaId, reloadCallback, reload}) {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const [criteria, setCriteria] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [assetTypes, setAssetTypes] = useState([]);
    const [selectedAssetType, setSelectedAssetType]= useState(null);
    const [modules, setModules] = useState ([]);
    const [selectedModules, setSelectedModules] = useState ([]);
    const [services, setServices] = useState();
    const [selectedService, setSelectedService] = useState(null);
    const [userName, setUserName] = useState("");
    const [userKey, setUserKey] = useState(null);
    //const [reloadTrigger, setReloadTrigger] = useState(0);


    const handleCustomerChange = (value) => {
        setSelectedCustomers(value)
    };

    const handleOk = async () => {
        try {
            await form.validateFields()
                const formValues = form.getFieldsValue();
                let payload = createPayload(formValues)
                const editResult = await handleCreation(auth, payload)
                modalIsOpenCallback(false)
                form.resetFields();
                if (editResult === 200) {
                    let triggerCounter = reload;
                    triggerCounter++;
                    reloadCallback(triggerCounter);
                }
        } catch (error) {
            console.log(error)
        }
    };

    async function loadDataFromManuManagement(path, setCallback) {
        try {
            const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + path)
            setCallback(jsonData);
        } catch (error) {
            console.log(error)
        }
    }

    //Fetch Services from server
    async function loadServicesForAssetType() {
        if (selectedAssetType !== null && selectedAssetType !== 0) {
            try {
                const jsonData = await getApi(auth, process.env.REACT_APP_MANU_MANAGEMENT_URL + "/asset-types/" + selectedAssetType + "/details")
                setServices(jsonData);
            } catch (error) {
                console.log(error)
            }
        }
    }

    function createPayload(formData) {
        return {
            "user_key": userKey,
            "customers": formData.customers,
            "asset_type": formData.asset_types,
            "service": formData.services,
            "modules": formData.modules
        }
    }

    async function handleCreation(auth, payload) {
        try {
            const result = await payloadApi(
                auth,
                process.env.REACT_APP_MANU_MANAGEMENT_URL + "/criteria/" + criteriaId,
                "PUT",
                payload
            );
            showCreateAlert(result);
            return result.status;
        } catch (error) {
            console.log(error)
        }
    }

    //Show Criteria Update Alert
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, status, desc, title, errorCodeString) => {
        api[status]({
          message: title,
          description:
            <div>
                <div>
                    {desc}
                </div>
                <div>
                    {errorCodeString}
                </div>
            </div>
        });
    };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response.status === 200) {
            title = t('administration.notifications.form.update.criteria.update-message.title.success');
            desc = t('administration.notifications.form.update.criteria.update-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('administration.notifications.form.update.criteria.update-message.title.error');
            desc = t('administration.notifications.form.update.criteria.update-message.body.error');
            errorCode = '[' + t('administration.notifications.form.update.criteria.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }

    const handleCancel = () => {
        modalIsOpenCallback(false)
        form.resetFields();
    };

    useEffect(() => {
        if (criteriaId > 0) {
            form.resetFields();
            setSelectedCustomers([]);
            setUserName("");
            setSelectedAssetType(null);
            setSelectedService(null);
            setSelectedModules([]);
            loadDataFromManuManagement("/criteria/" + criteriaId, setCriteria)
        }
        // eslint-disable-next-line
    }, [criteriaId, isOpen]);

    useEffect(() => {
        // Set name
        if (Object.keys(criteria).length > 0) {
            userList.forEach((user) => {
                if (user.id === criteria.user_key) {
                    setUserName(user.name)
                }
            })
            //Set User Key
            setUserKey(criteria.user_key)

            // Set Customers
            setSelectedCustomers(criteria.customers);

            // Set Asset Type
            setSelectedAssetType(criteria.asset_type);

            //Set Service
            if (criteria.service !== "") {
                setSelectedService(criteria.service);
            }

            //Set Modules
            setSelectedModules(criteria.modules)
        }
      
        // eslint-disable-next-line
    }, [criteria]);

    useEffect(() =>  {
        setCustomers(customerList);
        setAssetTypes(assetTypesList);
    }, [userList, customerList, assetTypesList])

    

    useEffect(() => {
        let modulesOptions = [];
        if (selectedService !== "" && services) {
            switch (selectedService) {
                case "guided":
                    modulesOptions = services.service_guided.modules;
                    break;
                case "onsite":
                    modulesOptions = services.service_onsite.modules;
                    break;
                case "remote":
                    modulesOptions = services.service_remote.modules;
                    break;
                default:
                    modulesOptions = [];
                    break;
            }
        }
        setModules(modulesOptions);
    }, [selectedService, services])

    useEffect(() => {
        form.setFieldsValue({users: userName})
    }, [userName])

    useEffect(() => {
        form.setFieldsValue({customers: selectedCustomers})
    }, [selectedCustomers])

    useEffect(() => {
        if (selectedAssetType === 0) {
            setSelectedAssetType(null);
        }
        form.setFieldsValue({asset_types: selectedAssetType})
        loadServicesForAssetType();
        // eslint-disable-next-line
    }, [selectedAssetType])

    useEffect(() => {
        form.setFieldsValue({services: selectedService})
    }, [selectedService])

    useEffect(() => {
        form.setFieldsValue({modules: selectedModules})
    }, [selectedModules])





    return (
        <>
            {contextHolder}
            <Modal
                title={t('administration.notifications.form.update')}
                open={isOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                >
                    <Form.Item
                        label={t('administration.mail-notifications.users')}
                        name="users"
                    >
                        <Select
                            disabled={true}
                            value={userName}
                        >
                            <Select.Option key={userName}>
                                {userName}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.customers')}
                        name="customers"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.customers')}
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            onChange={handleCustomerChange}
                            value={selectedCustomers}
                        >
                            {customers.map(customer => (
                                <Select.Option key={customer.id} value={customer.id} searchterm={customer.name}>
                                    {customer.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.asset-types')}
                        name="asset_types"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.asset-types')}
                            showSearch
                            filterOption={(input, option) => (option?.searchterm ?? '').toLowerCase().includes(input.toLowerCase())}
                            onChange={(value) => setSelectedAssetType(value)}
                            options={
                                assetTypes.map((item) => ({
                                    label: item.product_line.name,
                                    options: item.asset_types.map(asset => ({
                                        label: asset.name,
                                        value: asset.id,
                                        searchterm: asset.name
                                    })),
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.services')}
                        name="services"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.services')}
                            onChange={(value) => setSelectedService(value)}
                        >
                            {services && Object.keys(services).length > 0 && (
                                <>
                                    <Select.Option key={"guided"} value={"guided"} disabled={!services.service_guided.available}>
                                        <div style={{ display: "flex", alignItems: "center"}}><CustomerServiceOutlined /><span style={{marginLeft: "4px"}}>Guided</span></div>
                                    </Select.Option>
                                    <Select.Option key={"onsite"} value={"onsite"} disabled={!services.service_onsite.available}>
                                        <div style={{ display: "flex", alignItems: "center"}}><CarOutlined /><span style={{marginLeft: "4px"}}>Onsite</span></div>
                                    </Select.Option>
                                    <Select.Option key={"remote"} value={"remote"} disabled={!services.service_remote.available}>
                                        <div style={{ display: "flex", alignItems: "center"}}><LaptopOutlined /><span style={{marginLeft: "4px"}}>Remote</span></div>
                                    </Select.Option>
                                </>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('administration.mail-notifications.modules')}
                        name="modules"
                    >
                        <Select
                            placeholder={t('administration.mail-notifications.placeholder.modules')}
                            mode="multiple"
                            options={
                                modules.map((item) => ({
                                    label: item.name,
                                    key: item.id,
                                    value: item.id
                                }))
                            }
                        >
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EditNotificationModal