import { Link } from "react-router-dom";
import {Card, Avatar, Row, Col, Divider, Tag, Tooltip} from 'antd';
import {getDateFormat, getAvatarColor, getCaseTypeIcon} from "../../utils/utils";
import dayjs from 'dayjs';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getPrioTagColor } from "../../utils/utils";
import {CalendarOutlined, ScheduleOutlined, ThunderboltOutlined} from "@ant-design/icons";
const { Meta } = Card;

function TicketKanbanCard({ticket}) {

    const [manufacturerName, setManufacturerName] = useState(ticket.manufacturer_engineer_name);
    const [manufacturerSignature, setManufacturerSignature] = useState(ticket.manufacturer_engineer_signature);

    const { t, i18n } = useTranslation();

    function getPrioTag(prio) {
        let title;
        switch (prio) {
            case 'MINOR':
                title = <Tag color={getPrioTagColor('MINOR')}>{t('tickets.details.priority-options.minor')}</Tag>
                break;
            case 'MAJOR':
                title = <Tag color={getPrioTagColor('MAJOR')}>{t('tickets.details.priority-options.major')}</Tag>
                break;
            case 'CRITICAL':
                title = <Tag color={getPrioTagColor('CRITICAL')}>{t('tickets.details.priority-options.critical')}</Tag>
                break;
            default:
                title = ""
                break;
        }
        return title;
    }

    useEffect(() => {
        if(ticket.manufacturer_engineer_name === "") {
            setManufacturerName(t('requests.overview.not-assigned'));
        }
        if(ticket.manufacturer_engineer_signature === "") {
            setManufacturerSignature("--");
        }
    }, [ticket.manufacturer_engineer_name, ticket.manufacturer_engineer_signature])

    return (
        <div>
            <Card
                className="tickets-kanban-card"
            >   
                <Meta
                    className="tickets-kanban-card-name"
                    title={
                        <div>
                            <>
                                <Row justify="space-between" align="middle">
                                    <Col span={16}>
                                        <div style={{opacity: '0.4'}}>
                                            <b>{ticket.customer_name}</b>
                                        </div>
                                        <div style={{opacity: '0.4'}}>
                                                <i>{ticket.asset.type_name + " | Projekt: " + ticket.asset.project_number}</i>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div style={{opacity: '0.4'}}>
                                            {t('tickets.details.service-type-incoming')}:
                                        </div>
                                        <div style={{opacity: '0.4'}}>
                                            {t('tickets.details.service-type-processing')}:
                                        </div>
                                    </Col>
                                    <Col span={3}>
                                        <div style={{opacity: '0.4'}}>
                                            {t('tickets.details.service-type-incoming-options.' + ticket.service_type_incoming.toLowerCase())}
                                        </div>
                                        <div style={{opacity: '0.4'}}>
                                            {t('tickets.details.service-type-processing-options.' + ticket.service_type_processing.toLowerCase())}
                                        </div>
                                    </Col>
                                </Row>
                                <Divider style={{margin: '12px 0px 12px 0px'}} />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'}}
                                >
                                    <div>
                                        <Link to={`/tickets/${ticket.id}`}>
                                            <Tooltip title={t('tickets.details.case-type-options.' + ticket.case_type.toLowerCase())}>
                                                {getCaseTypeIcon(ticket.case_type)}
                                            </Tooltip>
                                            &nbsp;
                                            {t('tickets.details.area-options.' + ticket.area.toLowerCase())} - {ticket.case_detail}
                                        </Link>
                                    </div>
                                    <div>
                                        {getPrioTag(ticket.priority)}
                                    </div>
                                </div>
                            </>
                            <Divider style={{margin: '12px 0px 0px 0px'}} />
                            <p style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                whiteSpace: 'normal',
                            }}>
                                {ticket.description.length > 140 ?
                                    `${ticket.description.substring(0, 140)}...` : ticket.description}
                            </p>
                        </div>
                    }
                    description={
                        <div>
                            <Divider style={{margin: '0px 0px 12px 0px'}} />
                            <Row justify="space-between" align="middle">
                                <Col span={12} >
                                    <div>{t('tickets.details.updated')}: {dayjs(ticket.updated_at).format(getDateFormat(i18n.language))}</div>
                                    <div>{t('tickets.details.id')}: {ticket.id}</div>
                                </Col>
                                <Col >
                                    <Avatar style={{ backgroundColor: getAvatarColor(manufacturerSignature)}}>{manufacturerSignature}</Avatar> {manufacturerName}
                                </Col>
                            </Row>
                        </div>
                    }    
                />  
            </Card>
        </div>
    )
}

export default TicketKanbanCard;
