import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {fetchApi} from "./RequestBuilder";
import {List} from "antd";
import {
    EditOutlined, 
    FileImageOutlined, 
    FileExcelOutlined, 
    FileJpgOutlined, 
    FilePdfOutlined, 
    FileOutlined, 
    FileZipOutlined,
    FolderOpenOutlined } from "@ant-design/icons";

function DocumentsArea({objectId, auth, type}) {
    const { t } = useTranslation();
    const [ attachments, setAttachments ] = useState({dir: { link: ""}});

    //Fetch attachments from Server
    async function loadMessageData() {
        let path = "";
        if (type === "ticket") {
            path = "/tickets/" + objectId + "/attachments";
        } else if (type === "asset") {
            path = "/assets/" + objectId + "/attachments";
        }
        try {
            const jsonData = await fetchApi(auth, path )
            setAttachments(jsonData);
        } catch (error) {
            // Handle error
        }
    }
    //Get Icon
    function getIconForFileType(contentType) {

        if (contentType === "directory") {
            return <FolderOpenOutlined />
        } else {
            const extension = contentType.split("/").pop().toLowerCase();
        
            switch (extension) {
            case "pdf":
                return <FilePdfOutlined />;
            case "jpg":
            case "jpeg":
            case "png":
                return <FileImageOutlined />;
            case "xls":
            case "xlsx":
                return <FileExcelOutlined />;
            case "zip":
                return <FileZipOutlined />;
            default:
                return <FileOutlined />;
            }
        }
      }


    // Load attachments list
    useEffect(() => {
        if (auth.user !== undefined) {
            loadMessageData()
        }
        // eslint-disable-next-line
    }, [auth, objectId]);

    return (
        <div
            style={{paddingTop: "4px"}}
        >
            <p>
                <a
                    href={attachments.dir?.link}
                    target="_blank"
                    className={"non-colored-link"}
                >
                    <EditOutlined /> {t('tickets.details.documents-area.action.administration')}
                </a>
            </p>
            <List
                size="small"
                bordered={true}
                dataSource={attachments.content}
                renderItem={(item) =>
                    <List.Item>
                        <a href={item.link} target="_blank">{getIconForFileType(item.content_type)}{" "}{item.title}</a>
                    </List.Item>
                }
            />
        </div>
    );
}

export default DocumentsArea;
