import { useTranslation } from 'react-i18next';
import { Form, Modal, notification, Button, Tooltip } from 'antd';
import { useEffect, useState } from "react";
import { createNewRequest } from '../../utils/RequestBuilder';
import RequestCreateForm from "./Forms/RequestCreateForm";

function AddRequestModal({isOpen, auth, customers, manufacturerEngineers, callbackFct}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [syncTrigger, setSyncTrigger] = useState(0) ;
    const [form] = Form.useForm();

    //Show Ticket Create Alert
    const [api, contextHolder] = notification.useNotification()

    const handleOk = () => {
        form.validateFields().then(() => {
            handleTicketCreation(auth, createTicketArray(form.getFieldsValue()))
            setIsModalOpen(false)
            form.resetFields()
        })
    }

    const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         })
    }

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response == 200) {
            title = t('tickets.forms.create.create-message.title.success');
            desc = t('tickets.forms.create.create-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('tickets.forms.create.create-message.title.error');
            desc = t('tickets.forms.create.create-message.body.error');
            errorCode = '[' + t('tickets.forms.create.create-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    function createTicketArray(formData) {
        return [
            {attributeName: "customer", attributeValue: formData.customer},
            {attributeName: "asset", attributeValue: formData.asset},
            {attributeName: "contact", attributeValue: formData.contact},
            {attributeName: "area", attributeValue: formData.caseTopSelect},
            {attributeName: "case_type", attributeValue: formData.caseType},
            {attributeName: "case_detail", attributeValue: formData.caseDetailSelect[1]},
            {attributeName: "location", attributeValue: formData.location[1]},
            {attributeName: "description", attributeValue: formData.description},
            {attributeName: "status", attributeValue: formData.ticketStatus},
            {attributeName: "assigned_to_id", attributeValue: formData.manufacturerEngineerSelect},
            {attributeName: "priority", attributeValue: formData.priority},
            {attributeName: "service_type_incoming", attributeValue: formData.serviceTypeIncoming},
            {attributeName: "service_type_processing", attributeValue: formData.serviceTypeProcessing},
            {attributeName: "comment_internal", attributeValue: formData.comment},
        ]
    }

    async function handleTicketCreation(auth, ticketArray) {
        try {
            const result = await createNewRequest(auth, "/customers/tickets", ticketArray);
            showCreateAlert(result);
            if (result === 200) {
                callbackFct();
            } 
        } catch (error) {
            console.error(error);
            //showAlert(error.status)
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        // eslint-disable-next-line
    }, [isOpen])

    // Function to handle sync
    const syncSFData = () => {
        const trigger = syncTrigger + 1;
        setSyncTrigger(trigger);
    };


    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('tickets.overview.add')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <>
                        <Tooltip title={t('requests.forms.create.footer.sync')}>
                            <Button
                                className="request-create-sync"
                                key="sync"
                                type="primary"
                                icon={<img src="/img/logos/salesforce_Logo.svg" alt="salesforce logo" style={{ width: 16, height: 16 }} />}
                                onClick={syncSFData}
                            />
                        </Tooltip>
                        <Button
                            key="cancel"
                            onClick={handleCancel}>
                            {t('requests.forms.create.footer.cancel')}
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}>
                            {t('requests.forms.create.footer.ok')}
                        </Button>
                    </>
                ]}
            >
                <RequestCreateForm
                    form={form}
                    auth={auth}
                    customers={customers}
                    manufacturerEngineers={manufacturerEngineers}
                    syncTrigger={syncTrigger}
                />
            </Modal>
        </>
    );
}

export default AddRequestModal;
