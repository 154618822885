import React, {useEffect, useState} from 'react';
import { Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { transformToSelectablesUtils } from '../../utils/utils';

const defaultData = [
    {
        title: "Melt Deck",
        key: "001",
        children: [
            {
                title: "Furnace #1",
                key: "001.1"
            },
            {
                title: "Furnace #2",
                key: "001.2"
            }
        ]
    },
    {
        title: "Molding Line",
        key: "002",
        children: [
            {
                title: "DISA",
                key: "0020.1"
            },
            {
                title: "Pouring Furnace",
                key: "0020.2"
            }
        ]
    }
];

const selectables2 = {
    "001": {
        "type": "workstation",
        "name": "Melt Deck",
        "internal_identifier": 1,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 1
    },
    "001.1": {
        "name": "Furnace #1",
        "internal_identifier": 1,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 1,
        "type": "OCC",
        "manufacturer": "ABP",
        "equipment_number": "7654rfg"

    },
    "001.2": {
        "name": "Furnace #2",
        "internal_identifier": 2,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 2,
        "type": "OCC",
        "manufacturer": "ABP",
        "equipment_number": "7654rfg"
    },
    "002": {
        "type": "workstation",
        "name": "Molding Line",
        "internal_identifier": 2,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 2
    },
    "002.1": {
        "name": "DISA",
        "internal_identifier": 1,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 1,
        "type": "OCC",
        "manufacturer": "ABP",
        "equipment_number": "7654rfg"
    },
    "002.2": {
        "name": "Pouring Furnace",
        "internal_identifier": 2,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "position": 2,
        "type": "OCC",
        "manufacturer": "ABP",
        "equipment_number": "7654rfg"
    }
}

const transformationTest = [
    {
        "internal_identifier": 1,
        "id": "1c072968-1a55-11ee-be56-0242ac120002",
        "name": "Schmelzbetrieb",
        "position": 1,
        "assets": [
            {
                "internal_identifier": 1,
                "id": "533a496a-1a55-11ee-be56-0242ac120002",
                "name": "IFM 8",
                "position": 1
            },
            {
                "internal_identifier": 2,
                "id": "533a496a-1a55-11ee-be56-0242ac120003",
                "name": "ITMK-6",
                "position": 3
            },
            {
                "internal_identifier": 5,
                "id": "",
                "name": "FS-60",
                "position": 2
            }
        ]
    },
    {
        "internal_identifier": 2,
        "id": "1c072968-1a55-11ee-be56-0242ac120003",
        "name": "Gießlinie",
        "position": 2,
        "assets": [
            {
                "internal_identifier": 3,
                "id": "533a496a-1a55-11ee-be56-0242ac120004",
                "name": "Disamatic",
                "position": 2
            },
            {
                "internal_identifier": 4,
                "id": "533a496a-1a55-11ee-be56-0242ac120005",
                "name": "OCC Gießofen",
                "position": 1
            }
        ]
    }
];

const NavMenu = ({ setSelection, externalSetKey, assetList, menuItemKey }) => {

    const [gData, setGData] = useState(sortObject(transformData(assetList)));
    //const [defaultSelectedKeys] = useState(["001"]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([generateExpandedKeys(transformData(assetList))]);
    const navigate = useNavigate();
    const [selectables, setSelectables] = useState(transformToSelectablesUtils(assetList));

    // Generate Menu Tree Data
    function transformData(inputData) {
        const transformedData = inputData.map((workstation) => {
          const children = workstation.assets.map((asset) => {
            return {
              title: asset.name,
              key: `${workstation.position.toString().padStart(3, '0')}.${asset.position.toString().padStart(1, '0')}`,
            };
          });
      
          return {
            title: workstation.name,
            key: workstation.position.toString().padStart(3, '0'),
            children: children,
          };
        });

        /* const sortedData = transformedData.sort((a, b) => {
            return a.key.localeCompare(b.key);
        }); */
        /* const sortedData = transformedData.sort((a, b) => {
            const keyA = parseInt(a.key.split('.')[0]);
            const keyB = parseInt(b.key.split('.')[0]);
            return keyA - keyB;
        }); */
          
        return transformedData;
    }

    function sortObject(obj) {
        
        obj.sort((a, b) => parseInt(a.key) - parseInt(b.key));
    
       
        for (let i = 0; i < obj.length; i++) {
           
            if (obj[i].children && obj[i].children.length > 0) {
                
                obj[i].children.sort((a, b) => parseInt(a.key.split('.')[1]) - parseInt(b.key.split('.')[1]));
            }
        }
    
        return obj;
    }

    function sortArrayByKeys(arr) {
        arr.forEach((item) => {
          if (item.children) {
            item.children = sortArrayByKeys(item.children);
          }
        });
       return arr.sort((a, b) => a.key.localeCompare(b.key));
      }
      

    function generateExpandedKeys(inputData) {
        const workstationKeys = inputData.map((workstation) => workstation.key)
         return workstationKeys;
    }

    useEffect(() => {
        
        
        setExpandedKeys(generateExpandedKeys(transformData(assetList)));
        setSelectables(transformToSelectablesUtils(assetList))
        
    }, [assetList])

    useEffect(() => {
        setGData(sortObject((transformData(assetList))));
    }, [expandedKeys])


    // Generate Menu Tree Selectables
    function transformToSelectables(originalData) {
        const selectables = {};
      
        originalData.forEach((workstation) => {
          const workstationKey = workstation.position.toString().padStart(3, '0');
          const workstationName = workstation.name;
          const workstationInternalId = workstation.internal_identifier;
          const workstationId = workstation.id;
          const workstationPosition = workstation.position;
      
          selectables[workstationKey] = {
            type: "workstation",
            name: workstationName,
            internal_identifier: workstationInternalId,
            id: workstationId,
            position: workstationPosition,
          };
      
          if (workstation.assets) {
            workstation.assets.forEach((asset) => {
                const assetKey = `${workstationKey}.${asset.position.toString().padStart(1, '0')}`;
                const assetName = asset.name;
                const assetInternalId = asset.internal_identifier;
                const assetId = asset.id;
                const assetPosition = asset.position;
                const assetType = asset.type;
                const assetManufacturer = asset.manufacturer;
                const assetNo = asset.equipment_number;
      
                selectables[assetKey] = {
                    name: assetName,
                    internal_identifier: assetInternalId,
                    id: assetId,
                    position: assetPosition,
                    type: assetType,
                    manufacturer: assetManufacturer,
                    equipment_number: assetNo
                };
            });
          }
        });
        return selectables;
      }


    // Drag and Drop
    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };
        const data = [...gData];

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });
        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
            });
        } else if (
            (info.node.props.children || []).length > 0 &&
            // Has children
            info.node.props.expanded &&
            // Is expanded
            dropPosition === 1 // On the bottom gap
        ) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // item to the tail of the children
            });
        } else {
            let ar = [];
            let i;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }
        setGData(data);
        // navigate('/assets/1')
    };

    

    const onSelect = (info) => {
        if (info.length > 0) {
            setSelection(
                info[0], 
                selectables[info[0]].name, 
                selectables[info[0]].type,
                selectables[info[0]].internal_identifier,
                selectables[info[0]].id,
                selectables[info[0]].position,
                selectables[info[0]].type,
                selectables[info[0]].manufacturer,
                selectables[info[0]].equipment_number)
            setSelectedKeys([info[0]])
        }
    }

    useEffect(() => {
        if (selectables && Object.keys(selectables).length > 0) {
            setSelection(
                menuItemKey, 
                selectables[menuItemKey].name, 
                selectables[menuItemKey].type,
                selectables[menuItemKey].internal_identifier,
                selectables[menuItemKey].id,
                selectables[menuItemKey].position,
                selectables[menuItemKey].type,
                selectables[menuItemKey].manufacturer,
                selectables[menuItemKey].equipment_number
            );
            setSelectedKeys(menuItemKey);
        }
    }, [selectables])


    useEffect(() => {
    }, [selectedKeys])

    return (
        <Tree
            defaultSelectedKeys={menuItemKey}
            expandedKeys={expandedKeys}
            onDrop={onDrop}
            onSelect={onSelect}
            treeData={gData}
            selectedKeys={selectedKeys}
        />
    );
};

export default NavMenu;
