import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, notification } from 'antd';
import {useEffect, useState} from "react";
import { changeTicketInfo } from '../../utils/RequestBuilder';
import { wait } from '@testing-library/user-event/dist/utils';
//import useAuth, { hasRole, userRoles } from "../../hooks/useAuth"

function EditWorkstationModal({isOpen, auth, id, name, callbackFct, selectId, setMenuItemKey}) {
    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()

   

    const handleOk = () => {
        form.validateFields().then(() => {
            const formValues = form.getFieldsValue();
            const WsInformationArray = createWsArray(formValues);
            setMenuItemKey(selectId)
            handleWsCreation(auth, WsInformationArray)
            setIsModalOpen(false)
            form.resetFields();
        })
    };

    function createWsArray(formData) {
        const WsInformationArray = [
            {attributeName: "name", attributeValue: formData.name},
        ]
        return WsInformationArray
    }

    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

     //Show Ticket Create Alert
     const [api, contextHolder] = notification.useNotification();
     const openNotification = (placement, status, desc, title, errorCodeString) => {
         api[status]({
           message: title,
           description:
             <div>
                 <div>
                     {desc}
                 </div>
                 <div>
                     {errorCodeString}
                 </div>
             </div>
         });
     };

    function showCreateAlert(response) {
        let desc = "";
        let title = "";
        let errorCode = "";
        if (response === 200) {
            title = t('assets.forms.edit.workstation.update-message.title.success');
            desc = t('assets.forms.edit.workstation.update-message.body.success');
            openNotification('topRight', 'success', desc, title, errorCode)
        } else {
            title = t('assets.forms.edit.update-message.title.error');
            desc = t('assets.forms.edit.update-message.body.error');
            errorCode = '[' + t('assets.forms.edit.workstation.update-message.status-code') + ': ' + response + ']'
            openNotification('topRight', 'error', desc, title, errorCode)
        }
    }

    async function handleWsCreation(auth, attributesArray) {
        try {
            const result = await changeTicketInfo(auth, "/workstations/", attributesArray, id);
            showCreateAlert(result);
            if (result === 200) {callbackFct();}
        } catch (error) {
            console.error(error);
        }
    }

    const onFinish = (values) => {
        form.resetFields();
    }
     
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields();
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
        if (isOpen) {
            form.setFieldsValue({name: name})
        }
        // eslint-disable-next-line
    }, [isOpen, form])


    return (
        <>
            {contextHolder}
            <Modal
                className="tickets-modal-create"
                title={t('assets.forms.edit.workstation.title')}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    {...layout}
                    autoComplete="off"
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        name: name,
                    }}
                >
                    <Form.Item
                        label={t('assets.forms.edit.asset.labels.name')}
                        name="name"
                        rules={[{
                            required: true,
                            message: t('assets.forms.edit.asset.labels.name') + ' ' + t('assets.forms.error-message')
                        }]} 
                    >
                        <Input  />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EditWorkstationModal;